import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { DELETE, PGET } from 'helpers/api'
import { formatDate } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)

	const [pagination] = useState({
		url: '/services/gocashweb/api/rfm-settings-pageList',
		response: null
	})
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(pagination.url, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function deleteSetting(item) {
		await DELETE(`/services/gocashweb/api/rfm-settings`, {
			settingId: item.id
		})
		getData()
	}

	async function paginate(data) {
		const response = await PGET(pagination.url, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('RFM_SETTINGS')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/rfm/settings/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={pagination.url} search={search}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME3')}</th>
									<th className="text-center">{t('ACTIVE_POINT_OF_SALES')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td className="text-center">{item.posCount}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-danger-button me-2" onClick={() => deleteSetting(item)}>
													<i className="uil-trash-alt"></i>
												</div>
												<Link to={'/rfm/settings/edit/' + item.id}>
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit"></i>
													</div>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index