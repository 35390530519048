import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, POST, GET } from 'helpers/api'
import { formatDate, returnSign, findFromArrayById } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const genders = [{ "id": 0, "name": t('MAN') }, { "id": 1, "name": t('WOMAN') }]

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)

	const [notification, setNotification] = useState({
		'title': '',
		'message': '',
		'sendAll': false,
		'usersId': [],
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		ageFrom: null,
		ageTo: null,
		gender: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/gocashweb/api/clients-info-pageList',
		response: null
	})

	async function sendNotification() {
		setShowModal(false)
		const response = await POST(
			`/services/gocashweb/api/clients-info-send-notification${filterData.urlParams}`,
			notification,
			{}
		)
		if (response) {
			toast.success(t('SUCCESS'))
		}
	}

	function openModal(item) {
		var notificationCopy = { ...notification }
		if (item) {
			notificationCopy.sendAll = false
			notificationCopy.usersId = []
			notificationCopy.usersId.push(item.userId)
		} else {
			notificationCopy.sendAll = true
			notificationCopy.usersId = []
		}
		setNotification(notificationCopy)
		setShowModal(true)
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/services/gocashweb/api/clients-info-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.ageFrom)
			urlParams += returnSign(urlParams) + 'ageFrom=' + filterData.ageFrom
		if (filterData.ageTo)
			urlParams += returnSign(urlParams) + 'ageTo=' + filterData.ageTo
		if (filterData.gender === 0 || filterData.gender === 1)
			urlParams += returnSign(urlParams) + 'gender=' + filterData.gender
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/gocashweb/api/clients-info-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/gocashweb/api/clients-info-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[
				t('CLIENT'), t('PHONE'), t('BIRTH_DAY'), t('AGE'), t('GENDER'),
				t('STATUS'), t('LAST_ACTIVITY')
			]]
			const response = await GET(filterUrl)

			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].clientName)
				excelItems.push(response[i].clientLogin)
				excelItems.push(response[i].birthDate)
				excelItems.push(response[i].age)
				excelItems.push(response[i].gender)
				excelItems.push(response[i].clientStatusName)
				excelItems.push(response[i].lastChequeDate)
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('CLIENTS') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
		}

		setDropdownIsOpen(false)
		setFilterData({ ...filterData, 'urlParams': urlParams })
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.ageFrom === null &&
			filterData.ageTo === null &&
			filterData.gender === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			ageFrom: null,
			ageTo: null,
			gender: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/gocashweb/api/clients-info-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/gocashweb/api/clients-info-pageList', 'response': response })
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/gocashweb/api/pos-helper', {}, {})
		if (response) {
			setPoses(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENTS')}
				</h4>
				<button className="btn-rounded btn btn-primary" onClick={() => openModal()}>
					{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
				</button>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('GENDER')}</label>
											<Select
												options={genders}
												value={genders.find(option => option.id === filterData.gender) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'gender': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('AGE_FROM')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'ageFrom': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label>{t('AGE_TO')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'ageTo': e.target.value })} />
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS_GROUP')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.ageFrom &&
									<span className="me-2">
										<b>{t('AGE_FROM')}: </b> {filterData.ageFrom}
									</span>
								}
								{filterData.ageTo &&
									<span className="me-2">
										<b>{t('AGE_TO')}: </b> {filterData.ageTo}
									</span>
								}
								{(filterData.gender === 0 || filterData.gender === 1) &&
									<span className="me-2">
										<b>{t('GENDER')}: </b>
										{filterData.gender === 1 ?
											<span>{t('WOMAN')}</span>
											:
											<span>{t('MAN')}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('CLIENT')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('BIRTH_DAY')}</th>
									<th className="text-center">{t('AGE')}</th>
									<th className="text-center">{t('GENDER')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('LAST_ACTIVITY')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={'/clients/' + item.userId}>
												{item.clientName}
											</Link>
										</td>
										<td className="text-center">{item.clientLogin}</td>
										<td className="text-center">{formatDate(item.birthDate)}</td>
										<td className="text-center">{item.age}</td>
										<td className="text-center">{item.gender === 0 ? t('MAN') : t('WOMAN')}</td>
										<td className="text-center">{item.clientStatusName}</td>
										<td className="text-center">{item.lastChequeDate ? formatDate(item.lastChequeDate) : ''}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-message"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{notification.sendAll ?
							<div>
								{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
							</div>
							:
							<div>
								{t('SEND_PUSH_NOTIFICATION')}
							</div>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => sendNotification()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME3')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'title': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'message': e.target.value })} />
						</div>
						<p className="text-danger">
							{t('YOU_ARE_RESPONSIBLE')}
						</p>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setShowModal(false)}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!notification.title || !notification.message}>{t('SEND')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
