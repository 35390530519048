import React from 'react'

function SortableTr({ name, tableKey, setFilterData, filterData, className }) {

	function sortTable() {
		var filterDataCopy = { ...filterData }
		console.log(filterDataCopy);
		if (filterDataCopy.tableSortKey === tableKey) {
			if (filterDataCopy.tableSortDirection === 'asc') {
				filterDataCopy.tableSortDirection = 'desc'
			} else {
				filterDataCopy.tableSortDirection = 'asc'
			}
		} else {
			filterDataCopy.tableSortDirection = 'asc'
		}
		filterDataCopy.tableSortKey = tableKey
		setFilterData(filterDataCopy)
	}

	return (
		<th className={"th-sortable cursor " + (className ?? '')}
			onClick={() => sortTable()}>
			{name}
			<div className="table-sort">
				<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === 'asc' && filterData.tableSortKey === tableKey) && 'sort-active')}></i>
				<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === 'desc' && filterData.tableSortKey === tableKey) && 'sort-active')}></i>
			</div>
		</th>
	)
}

export default SortableTr