import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import Rightbar from './Rightbar'
import { PGET, GET } from 'helpers/api'
import { formatDateWithTime, returnSign, formatDateBackend, findFromArrayById, formatMoney, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const statuses = [{ "id": 1, "name": t('SUCCESS') }, { "id": 2, "name": t('PARTIAL_RETURN') }, { "id": 3, "name": t('RETURN') }]

	const [poses, setPoses] = useState([])
	const [cashiers, setCashiers] = useState([])

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		cashierId: null,
		currencyId: null,
		status: null,
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		amountFrom: '',
		amountTo: '',
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/gocashweb/api/cheque-pageList',
		response: null
	})
	const [data, setData] = useState([])

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}
		if (boolean) {
			const response = await GET("/services/gocashweb/api/cheque-products/" + item.id, {}, { loader: true })
			dataCopy[index]['itemsList'] = response
			dataCopy[index]['showSidebar'] = boolean

			setSelectedItem(dataCopy[index])
			setShowRightBar(boolean)
		} else {
			setShowRightBar(boolean)
			setSelectedItem({})
		}
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/services/gocashweb/api/cheque-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.cashierName)
			urlParams += returnSign(urlParams) + 'cashierName=' + filterData.cashierName
		if (filterData.clientLogin)
			urlParams += returnSign(urlParams) + 'clientLogin=' + filterData.clientLogin
		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/gocashweb/api/cheque-pageList" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/gocashweb/api/cheque-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[
				t('DATE'), t('CHECK_AMOUNT'), t('PAID_IN_SUMS'), t('PAID_WITH_BALLS'), t('BONUS'),
				t('PHONE'), t('CASHIER'), t('POS')
			]]
			const response = await GET(filterUrl)

			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].chequeDate)
				excelItems.push(response[i].totalAmount)
				excelItems.push(response[i].cashPayment)
				excelItems.push(response[i].writeOff)
				excelItems.push(response[i].bonus)
				excelItems.push(response[i].clientLogin)
				excelItems.push(response[i].cashierName)
				excelItems.push(response[i].posName)
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('CHEQUES') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			cashierName: null,
			clientLogin: null,
			status: null,
			startDate: null,
			endDate: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/gocashweb/api/cheque-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/gocashweb/api/cheque-pageList', 'response': response })
		}
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.cashierName === null &&
			filterData.clientLogin === null &&
			filterData.status === null &&
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/gocashweb/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getCashiers() {
		const response = await GET('/services/gocashweb/api/cashier-helper')
		if (response) {
			setCashiers(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getCashiers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CHEQUES')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('STATUS')}</label>
											<Select
												options={statuses}
												value={statuses.find(option => option.id === filterData.status) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('CLIENT')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'clientLogin': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label>{t('CASHIER')}</label>
											<Select
												options={cashiers}
												value={cashiers.find(option => option.name === filterData.cashierName) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'cashierName': option.name })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.cashierName &&
									<span className="me-2">
										<b>{t('CASHIER')}: </b> {filterData.cashierName}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('STATUS')}: </b> {findFromArrayById(statuses, filterData.status)}
									</span>
								}
								{filterData.clientLogin &&
									<span className="me-2">
										<b>{t('CLIENT')}: </b> {filterData.clientLogin}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>{t('DATE')}</th>
									<th className="text-center text-nowrap">{t('CHECK_AMOUNT')}</th>
									<th className="text-center text-nowrap">{t('PAID_IN_SUMS')}</th>
									<th className="text-center text-nowrap">{t('PAID_WITH_BALLS')}</th>
									<th className="text-center text-nowrap">{t('BONUS')}</th>
									<th className="text-center">{t('CLIENT')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('POS')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('RETURN_AMOUNT')}</th>
									<th className="text-center">{t('RETURN_DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}
										key={index} onClick={() => toggleRightbar(true, item, index)}>
										<td>{formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatMoney(item.cashPayment)}</td>
										<td className="text-center">{formatMoney(item.writeOff)}</td>
										<td className="text-center">{formatMoney(item.bonus)}</td>
										<td className="text-center">{item.clientName}</td>
										<td className="text-center">{item.clientLogin}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.posName}</td>
										<td className="d-flex justify-content-center">
											{item.returnStatus === 0 &&
												<span className="bg-success bg-border text-white">{t('CONFIRMED')}</span>
											}
											{item.returnStatus === 1 &&
												<span className="bg-warning bg-border text-white text-nowrap">{t('PARTIAL_RETURN')}</span>
											}
											{item.returnStatus === 2 &&
												<span className="bg-danger bg-border text-white">{t('RETURN')}</span>
											}
										</td>
										<td className="text-center">{item.returnAmount ? formatMoney(item.returnAmount) : '-'}</td>
										<td className="text-center">{item.returnDate ? formatDate(item.returnDate) : '-'}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>
		</>
	)
}

export default Index
