import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import { DELETE, GET, POST, PUT } from 'helpers/api'
import { formatDate } from "helpers/helpers"

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();

	const navigate = useNavigate();

	const date = new Date()
	const list = [{ id: true, name: t('ALL') }, { id: false, name: t('CUSTOM_DATE') }];

	const [data, setData] = useState({
		'earnPointStatusRulesList': [],
		'posList': [],
		'serviceTypeId': 8,
		'name': "",
	})
	const [earnPointStatusRule, setEarnPointStatusRule] = useState({
		'clientStatusName': '',
		'award': '',
		'fromAmount': '',
		'showInput': false,
		"calendarDate": date,
		"allCalendarDates": true,
	})

	const [posGroups, setPosGroups] = useState([])
	const [holidays, setHolidays] = useState([])
	const [poses, setPoses] = useState([])

	async function createUpdate() {
		var dataCopy = { ...data }
		var response;
		dataCopy['posList'] = [];
		for (let j = 0; j < poses.length; j++) {
			dataCopy['posList'].push(poses[j])
		}
		if (!id) {
			response = await POST('/services/gocashweb/api/services-status-calendar-day', dataCopy)
		} else {
			response = await PUT('/services/gocashweb/api/services-status-calendar-day', dataCopy)
		}

		if (response) {
			navigate('/services-by-calendar-day')
		}
	}

	function addEarnPointStatusRule() {
		var checkForEquality = 0
		var dataCopy = { ...data }
		var earnPointStatusRuleCopy = { ...earnPointStatusRule }
		for (let i = 0; i < dataCopy.earnPointStatusRulesList?.length; i++) {
			// if (earnPointStatusRuleCopy.fromAmount === dataCopy.earnPointStatusRulesList[i]['fromAmount']) {
			// 	checkForEquality = 1
			// }
			if (earnPointStatusRuleCopy.award === dataCopy.earnPointStatusRulesList[i]['award']) {
				checkForEquality = 2
			}
			if (earnPointStatusRuleCopy.day === null) {
				checkForEquality = 3
			}
		}

		if (checkForEquality === 1) {
			toast.error(t('RULE_SAME_SCORE_EXIST'))
			return
		}

		if (checkForEquality === 2) {
			toast.error(t('RULE_SAME_AWARD_EXIST'))
			return
		}

		if (checkForEquality === 3) {
			toast.error(t('CHECK_FILLED_INPUTS'))
			return
		}

		dataCopy.earnPointStatusRulesList.push(earnPointStatusRuleCopy)
		setData(dataCopy)
		setEarnPointStatusRule({
			'clientStatusName': '',
			'award': '',
			'fromAmount': '',
			'showInput': false,
			"calendarDate": date,
		})
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/services-status-calendar-day/' + id, {}, { loader: true })
		if (response) {
			for (let i = 0; i < response.earnPointStatusRulesList?.length; i++) {
				response.earnPointStatusRulesList[i]['showInput'] = false
			}
			setData(response)
			getPosGroups()
			setPoses(response.posList)
		}
	}

	async function deletePos(index) {
		var copyPoses = [...poses]
		var item = copyPoses[index]
		if (item.serviceId) {
			await DELETE(`/services/gocashweb/api/pos-service-status-calendar-day`, {
				serviceId: data.id,
				servicePosId: item.serviceId,
			})
		}
		copyPoses?.splice(index, 1)
		getPosGroups()
		setPoses(copyPoses)
	}

	function deleteRule(index) {
		var dataCopy = { ...data }
		dataCopy.earnPointStatusRulesList.splice(index, 1)
		setData(dataCopy)
	}

	async function getPosGroups() {
		const response = await GET('/services/gocashweb/api/pos-for-service')
		if (response) {
			setPosGroups(response)
		}
	}

	async function getHolidays() {
		const response = await GET('/services/gocashweb/api/services-status-calendar-weekends-helper')
		if (response) {
			var arr = []
			for (let i = 0; i < response.length; i++) {
				arr.push(new Date(response[i].date))
			}
			setHolidays(arr)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		}
		getPosGroups()
		getHolidays()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const MultiValueRemove = () => {
		return <div className="ms-1"></div>;
	};

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CREATE')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-6">
							<h4>
								<strong>{t('REGULATIONS')}</strong>
							</h4>
							<hr />

							<div className="form-group w-50 mb-3">
								<label>{t('NAME3')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control"
									value={data.name}
									onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>

							<div>
								<table className="table table-striped mb-0 overflow-inherit">
									<thead>
										<tr>
											<th style={{ width: '20%' }}>{t('CUSTOMER_STATUSES')}</th>
											<th style={{ width: '20%' }}>{t('BONUS_PERCENTAGE')}</th>
											<th style={{ width: '20%' }}>{t('POINTS_MORE')}</th>
											<th style={{ width: '20%' }}>{t('DATE')}</th>
											<th style={{ width: '20%' }}></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="text" maxLength="20" className="form-control"
													value={earnPointStatusRule.clientStatusName}
													onChange={(e) => setEarnPointStatusRule({ ...earnPointStatusRule, 'clientStatusName': e.target.value })} />
											</td>
											<td>
												<input type="text" pattern="[0-9]*" className="form-control"
													value={earnPointStatusRule.award}
													onChange={(e) => setEarnPointStatusRule({ ...earnPointStatusRule, 'award': e.target.value.replace(/[^0-9.]/g, '') })} />
											</td>
											<td>
												<input type="text" pattern="[0-9]*" className="form-control"
													value={earnPointStatusRule.fromAmount}
													onChange={(e) => setEarnPointStatusRule({ ...earnPointStatusRule, 'fromAmount': e.target.value.replace(/[^0-9.]/g, '') })} />
											</td>
											<td>
												{earnPointStatusRule.allCalendarDates ?
													<Select
														options={list}
														value={list.find(item => item.id === earnPointStatusRule.allCalendarDates)}
														isClearable={false}
														onChange={(option) => setEarnPointStatusRule({ ...earnPointStatusRule, 'allCalendarDates': option.id })}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
													:
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={earnPointStatusRule.calendarDate}
														onChange={(date) => setEarnPointStatusRule({ ...earnPointStatusRule, 'calendarDate': date })}
														highlightDates={holidays}
													/>
												}
											</td>
											<td>
												<button className="btn btn-primary w-100" onClick={() => addEarnPointStatusRule()}
													disabled={!earnPointStatusRule.award || !earnPointStatusRule.clientStatusName || !earnPointStatusRule.fromAmount}>
													{t('ADD')}
												</button>
											</td>
										</tr>
										{data.earnPointStatusRulesList?.map((item, index) => (
											<tr key={index}>
												<td>{item.clientStatusName}</td>
												<td>{item.award}%</td>
												<td>{item.fromAmount}</td>
												<td>{!item.allCalendarDates ? formatDate(item.calendarDate) : t('ALL')}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-danger-button me-2" onClick={() => deleteRule(index)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>


						<div className="col-md-6">
							<h4>
								<strong>{t('places_of_implementation')}</strong>
							</h4>
							<hr />
							<div className="form-group">
								<label>{t('POS')}<span className="required-mark">*</span></label>
								<Select
									className="w-50 mb-3"
									options={posGroups}
									value={poses}
									isMulti
									isClearable={false}
									onChange={(options) => setPoses(options)}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
									components={{ MultiValueRemove }}
								/>
							</div>
							<div>
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('POS_GROUP')}</th>
											<th>{t('POS')}</th>
											<th className="text-end">{t('ADDRESS')}</th>
											<th className="text-end">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{poses?.map((item, index) => (
											<tr key={index}>
												<td>{item.posGroupName}</td>
												<td>{item.name}</td>
												<td className="text-end">{item.posAddress}</td>
												<td className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button me-2" onClick={() => deletePos(index)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="btn-wrapper">
						<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/services-by-calendar-day">{t('CANCEL')}</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide" onClick={() => createUpdate()}>
							{t('SAVE')}
						</button>
					</div>

				</div>
			</div>
		</>
	)
}
export default CreateUpdate