import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

//import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { POST } from 'helpers/api'

function TableControl({ modalTableControl, setModalTableControl, keyName }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)

	//const [selectedTable, setSelectedTable] = useState({})
	const [settings, setSettings] = useState({
		'size': 20,
		'decimalPoint': 2,
		'currencyId': "",
		'posId': "",
		'organizationId': "",
		'rememberLastDocumentInPreview': null,
		'users': {
			'carNumber': false,
			'carTypeName': false,
			'firstVisitDate': false,
			'lastVisitDate': false,
			'lastLogin': false,
		},
	})

	function handleChange(e) {
		var settingsCopy = { ...settings }
		settingsCopy[keyName][e.target.name] = e.target.checked
		setSettings(settingsCopy)
	}

	async function saveSettings() {
		var sendData = {
			'settings': JSON.stringify(settings)
		}
		const response = await POST("/services/web/api/user-settings", sendData, { loader: true })
		if (response) {
			dispatch({ type: 'SET_SETTINGS', payload: settings })
			setSettings(settings)
			setModalTableControl(false)
		}
	}

	useEffect(() => {
		if (
			settings &&
			reduxSettings &&
			Object.keys(settings).length === Object.keys(reduxSettings).length) {
			setSettings(reduxSettings)
		} else {
			for (var key in settings) {
				if (reduxSettings.hasOwnProperty(key)) { //IF OBJECT HAVE ALL KEYS
					if (
						settings[key] &&
						reduxSettings[key] &&
						Object.keys(settings[key]).length !== Object.keys(reduxSettings[key]).length
					) { //IF OBJECT OF OBJECT HAVE ALL KEYS

						for (var tableKey in settings[key]) {
							// if (reduxSettings[key].hasOwnProperty(tableKey)) {
							reduxSettings[key][tableKey] = settings[key][tableKey]
							// }
						}
						if (Object.keys(settings[key]).length < Object.keys(reduxSettings[key]).length) {
							for (tableKey in reduxSettings[key]) {
								if (!settings[key].hasOwnProperty(tableKey)) {
									if (tableKey !== '0') {
										delete reduxSettings[key][tableKey]
									}
								}
							}
						}
					}
					// if (Object.keys(reduxSettings[key])?.length > Object.keys(settings[key])?.length) {
					// 	console.log(reduxSettings[key]);
					// }
				} else {
					reduxSettings[key] = settings[key]
				}
			}
		}
		setSettings(reduxSettings)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// useDidMountEffect(() => {
	// 	setSettings(reduxSettings)
	// }, [reduxSettings]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* MODAL TABLE CONTROL */}
			<Modal show={modalTableControl} animation={false} centered size="sm" onHide={() => setModalTableControl(false)}>
				<Modal.Body>
					{Object.keys(settings[keyName]).map(key => (
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t(key)}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name={key}
									onChange={(e) => handleChange(e)}
									checked={settings[keyName][key]} />
							</div>
						</div>

						// <div key={key}>
						// 	{key}: {String(settings[keyName][key])}
						// </div>
					))
					}
					{/* {(settings[keyName] && (typeof settings[keyName]['wholesalePrice'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('WHOLESALE_PRICE')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="wholesalePrice"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['wholesalePrice']} />
							</div>
						</div>
					} */}
					<div className="d-flex justify-content-center w-100 mt-3">
						<button className="btn btn-primary table-control-button" onClick={() => saveSettings()}>{t('SAVE')}</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default TableControl