import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const URL = '/services/gocashweb/api/pos-group-pageList'

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET('/services/gocashweb/api/pos-group-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/gocashweb/api/pos-group-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('POS_GROUPS')}
				</h4>
				{/* <div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/pos-group-create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div> */}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('IMAGE2')}</th>
									<th>{t('NAME3')}</th>
									<th className="text-center">{t('ACTIVE_POINTS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td>
											{item.imageUrl ?
												<img src={'https://cabinet.cashbek.uz' + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td className="text-center">{item.countActivePos} / {item.countActivePos}</td>

										<td>
											<div className="d-flex justify-content-center">
												<Link className="table-action-button table-action-success-button me-2"
													to={'/pos-group/' + item.id} title={t('EDIT')}>
													<i className="uil-edit-alt"></i>
												</Link>
												<Link className="table-action-button table-action-danger-button me-2"
													to={'/pos-group/poses/' + item.id} title={t('POSES')}>
													<i className="uil-list-ui-alt"></i>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
