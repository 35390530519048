export let exportMenu = [
	{
		url: "/dashboard",
		i18n: "HOME",
		icon: "uil-home-alt",
		active: false,
	},
	{
		url: "/cashiers",
		i18n: "CASHIERS",
		icon: "uil-user-square",
		active: false,
	},
	{
		url: "/pos-group",
		i18n: "POS_GROUPS",
		icon: "uil-shop",
		active: false,
	},
	{
		url: "/services-by-points",
		i18n: "FUNDED_PROGRAM_BY_POINTS",
		icon: "uil-apps",
		active: false,
	},
	{
		url: "/services-by-amount",
		i18n: "FUNDED_PROGRAM_BY_AMOUNT",
		icon: "uil-apps",
		active: false,
	},
	{
		url: "/cheques",
		i18n: "CHEQUES",
		icon: "uil-invoice",
		active: false,
	},
	{
		url: "/clients",
		i18n: "CLIENTS",
		icon: "uil-users-alt",
		active: false,
	},
	{
		url: "/purchases",
		i18n: "PURCHASES",
		icon: "uil-bill",
		active: false,
	},
]