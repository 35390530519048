import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";

import { Dropdown, Modal, Form } from 'react-bootstrap'
// import { toast } from 'react-toastify'
import Select from 'react-select'
// import XLSX from 'xlsx'

import { Editor } from '@tinymce/tinymce-react';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET, PUT, POST, FILE, DOWNLOAD_FILE } from 'helpers/api'
import { formatDate, findFromArrayById, regexPhoneNumber, formatMoney, formatDateWithTime } from 'helpers/helpers'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom"
import SortableTr from "pages/parts/SortableTr"
import TableControl from "components/TableControl";

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const genders = [{ "id": 0, "name": t('MAN') }, { "id": 1, "name": t('WOMAN') }]

	const editorRefRu = useRef(null);
	const editorRefUz = useRef(null);

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [messages, setMessages] = useState([])

	const [showModal, setShowModal] = useState(false)
	const [showNotificationModal, setShowNotificationModal] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)

	const [notification, setNotification] = useState({
		'titleUz': '',
		'titleRu': '',
		'messageUz': '',
		'messageRu': '',
		'sendAll': false,
		'sendParticularly': false,
		'usersId': [],
		'imageUrl': '',
	})
	const [payment, setPayment] = useState({
		'userId': '',
		'amount': '',
		'comment': '',
	})
	const [selectedItem, setSelectedItem] = useState({})
	const [filterData, setFilterData] = useState({
		posId: null,
		ageFrom: null,
		ageTo: null,
		gender: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/gocashweb/api/users-pageList',
		response: null
	})

	async function sendNotification() {
		console.log(notification);
		var notificationCopy = { ...notification }
		if (editorRefUz.current) {
			notificationCopy.messageUz = editorRefUz.current.getContent();
		}
		if (editorRefRu.current) {
			notificationCopy.messageRu = editorRefRu.current.getContent();
		}
		setShowModal(false)
		const response = await POST(
			`/services/gocashweb/api/users-send-notification`,
			{ ...notificationCopy, ...filterData },
			{ loader: true }
		)
		if (response) {
			// toast.success(t('SUCCESS'))
			setShowNotificationModal(false)
		}
	}

	function handleChange(index) {
		var notificationCopy = { ...notification }
		var dataCopy = [...data]
		dataCopy[index].checked = !dataCopy[index].checked
		var ids = dataCopy?.filter(item => item.checked)
		if (ids?.length > 0) {
			notificationCopy.sendParticularly = true
			notificationCopy.usersId = [];
			for (let i = 0; i < ids.length; i++) {
				notificationCopy.usersId.push(ids[i]['userId'])
			}
			setNotification({ ...notification, ...notificationCopy })
		} else {
			setNotification({ ...notification, sendParticularly: false, usersId: [] })
		}
		setData(dataCopy)
	}

	function openNotificationModal(item) {
		var notificationCopy = { ...notification }
		if (item) {
			notificationCopy.sendAll = false
			notificationCopy.usersId = []
			notificationCopy.usersId.push(item.userId)
		} else {
			notificationCopy.sendAll = true
			notificationCopy.usersId = []
		}
		setNotification(notificationCopy)
		setShowNotificationModal(true)
	}

	function fileUpload(e, name) {
		let formData = new FormData();
		var reader = new FileReader();
		var file = e.target.files[0];
		if (file) {
			reader.readAsDataURL(file);
			reader.onload = function (e) {
				var image = new Image();
				image.src = e.target.result;
				image.onload = async function () {
					// var height = this.height;
					// var width = this.width;
					formData.append("image", file);
					const response = await FILE('/services/gocashweb/api/pos-image-upload', formData);
					setNotification({ ...notification, 'imageUrl': response[0] })
				};
			};
		}
	}

	async function topUpBalance(e) {
		e.preventDefault();

		var response = await POST('/services/gocashweb/api/payment-users', payment, { loader: true })

		if (response) {
			setModalPayment(false)
			getData()
		}
	}

	async function showPaymentModal(item) {
		setPayment({ ...payment, userId: item.userId })
		setModalPayment(true)
	}

	async function searchWithFilter(exportToExcel = false) {
		var urlParams = ""
		checkFilter()

		var filterDataCopy = { ...filterData }

		if (filterDataCopy.tableSortKey) {
			filterDataCopy.sort = filterDataCopy.tableSortKey + ',' + filterDataCopy.tableSortDirection
		}

		if (exportToExcel) {
			await DOWNLOAD_FILE(`/services/gocashweb/api/users-pageList-excel`, t('CLIENTS'), filterDataCopy)
			return;
		}

		const response = await PGET('/services/gocashweb/api/users-pageList', filterDataCopy, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'response': response })
		}


		setDropdownIsOpen(false)
		setFilterData({ ...filterData, 'urlParams': urlParams })
	}

	async function createUpdate() {
		var selectedItemCopy = { ...selectedItem }
		var response;
		response = await PUT('/services/gocashweb/api/users', selectedItemCopy, { loader: true })
		if (response) {
			searchWithFilter(false)
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		itemCopy.cardNumber = itemCopy.cardNumber ? itemCopy.cardNumber : ''
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.ageFrom === null &&
			filterData.ageTo === null &&
			filterData.gender === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			ageFrom: null,
			ageTo: null,
			gender: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function selectMessage(option) {
		console.log(editorRefRu.current);
		const response = await GET(`/services/gocashweb/api/messages/${option.id}`)
		if (response) {
			var selectedItemCopy = { ...notification }
			editorRefRu.current.setContent(response.messageRu)
			editorRefUz.current.setContent(response.messageUz)
			selectedItemCopy.messageId = option.id;
			selectedItemCopy.titleRu = response.titleRu;
			selectedItemCopy.titleUz = response.titleUz;
			setNotification(selectedItemCopy)
		}
	}

	async function getData() {
		const response = await PGET('/services/gocashweb/api/users-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/gocashweb/api/users-pageList', 'response': response })
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/gocashweb/api/pos-helper', {}, {})
		if (response) {
			setPoses(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function getMessages() {
		const response = await GET('/services/gocashweb/api/messages-helper?messageType=1')
		if (response) {
			setMessages(response)
		}
	}

	useEffect(() => {
		getPoses()
		getMessages()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.tableSortKey, filterData.tableSortDirection]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENTS')}
				</h4>
				{notification?.sendParticularly ?
					<button className="btn-rounded btn btn-primary" onClick={() => setShowNotificationModal(true)}>
						{t('SEND_PUSH_NOTIFICATION_PARTICULARY')}
					</button>
					:
					<button className="btn-rounded btn btn-primary" onClick={() => openNotificationModal()}>
						{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
					</button>
				}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('GENDER')}</label>
											<Select
												options={genders}
												value={genders.find(option => option.id === filterData.gender) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'gender': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('AGE_FROM')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'ageFrom': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label>{t('AGE_TO')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'ageTo': e.target.value })} />
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS_GROUP')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.ageFrom &&
									<span className="me-2">
										<b>{t('AGE_FROM')}: </b> {filterData.ageFrom}
									</span>
								}
								{filterData.ageTo &&
									<span className="me-2">
										<b>{t('AGE_TO')}: </b> {filterData.ageTo}
									</span>
								}
								{(filterData.gender === 0 || filterData.gender === 1) &&
									<span className="me-2">
										<b>{t('GENDER')}: </b>
										{filterData.gender === 1 ?
											<span>{t('WOMAN')}</span>
											:
											<span>{t('MAN')}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th></th>
									<SortableTr
										tableKey="userName"
										name={t('CLIENT')}
										filterData={filterData}
										setFilterData={setFilterData}
									/>
									<th className="text-center">{t('PHONE')}</th>
									<SortableTr
										tableKey="gender"
										name={t('GENDER')}
										filterData={filterData}
										setFilterData={setFilterData}
										className="text-center"
									/>
									<SortableTr
										tableKey="birthDate"
										name={t('BIRTH_DAY')}
										filterData={filterData}
										setFilterData={setFilterData}
										className="text-center"
									/>
									<th className="text-end">{t('NEXT_BIRTHDAY')}</th>
									<SortableTr
										tableKey="age"
										name={t('AGE')}
										filterData={filterData}
										setFilterData={setFilterData}
										className="text-center"
									/>
									{(!reduxSettings.users || reduxSettings.users?.carNumber) &&
										<SortableTr
											tableKey="carNumber"
											name={t('carNumber')}
											filterData={filterData}
											setFilterData={setFilterData}
											className="text-center"
										/>
									}
									{reduxSettings?.users?.carTypeName &&
										<SortableTr
											tableKey="carTypeName"
											name={t('carTypeName')}
											filterData={filterData}
											setFilterData={setFilterData}
											className="text-center"
										/>
									}
									<SortableTr
										tableKey="cardNumber"
										name={t('CARD_NUMBER')}
										filterData={filterData}
										setFilterData={setFilterData}
									/>
									{reduxSettings?.users?.firstVisitDate &&
										<SortableTr
											tableKey="firstVisitDate"
											name={t('firstVisitDate')}
											filterData={filterData}
											setFilterData={setFilterData}
										/>
									}
									{reduxSettings?.users?.lastVisitDate &&
										<SortableTr
											tableKey="lastVisitDate"
											name={t('lastVisitDate')}
											filterData={filterData}
											setFilterData={setFilterData}
										/>
									}
									{reduxSettings?.users?.lastLogin &&
										<SortableTr
											tableKey="lastLogin"
											name={t('lastLogin')}
											filterData={filterData}
											setFilterData={setFilterData}
										/>
									}
									<SortableTr
										tableKey="lastVisitDay"
										name={t('LAST_VISIT_DAY')}
										filterData={filterData}
										setFilterData={setFilterData}
									/>
									<SortableTr
										tableKey="balance"
										name={t('BALANCE')}
										filterData={filterData}
										setFilterData={setFilterData}
										className="text-end"
									/>
									<SortableTr
										tableKey="clientStatusName"
										name={t('STATUS')}
										filterData={filterData}
										setFilterData={setFilterData}
										className="text-center"
									/>
									<th className="text-center table-control">
										{t('ACTION')}
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>
											<Form.Check // prettier-ignore
												type="checkbox"
												value={item.checked}
												checked={item.checked}
												onChange={() => handleChange(index)}
											/>
										</td>
										<td>
											<Link to={`/clients/${item.userId}`}>
												{item.userName ? item.userName : t('NOT_FOUND')}
											</Link>
										</td>
										<td className="text-center">{regexPhoneNumber(item.userPhone)}</td>
										<td className="text-center">{item.gender === 0 ? t('MAN') : t('WOMAN')}</td>
										<td className={"text-center " + (item.birthday ? 'text-success' : '')}>{formatDate(item.birthDate)}</td>
										<td className="text-end">{item.nextBirthday}</td>
										<td className="text-center">{item.age}</td>
										{reduxSettings?.users?.carNumber &&
											<td className="text-center">{item.carNumber}</td>
										}
										{reduxSettings?.users?.carTypeName &&
											<td className="text-center">{item.carTypeName}</td>
										}
										<td>{item.cardNumber ? item.cardNumber : ''}</td>
										{reduxSettings?.users?.firstVisitDate &&
											<td className="text-center">{item.firstVisitDate ? formatDate(item.firstVisitDate) : ''}</td>
										}
										{reduxSettings?.users?.lastVisitDate &&
											<td className="text-center">{item.lastVisitDate ? formatDate(item.lastVisitDate) : ''}</td>
										}
										{reduxSettings?.users?.lastLogin &&
											<td className="text-center">{formatDateWithTime(item.lastLogin)}</td>
										}
										<td>
											<b className={item.lastVisitDay > 30 ? 'text-danger' : ''}>
												{item.lastVisitDay}
											</b>
										</td>
										<td className={"fw-bold text-end " + (item.balance > 0 ? 'text-success' : '')}>{formatMoney(item.balance, 3)}</td>
										<td className="text-center">{item.clientStatusName}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-warning-button me-2" onClick={() => openModal(item)}
												title={t('EDIT')}>
												<i className="uil-edit-alt"></i>
											</div>
											<div className="table-action-button table-action-primary-button me-2" onClick={() => openNotificationModal(item)}
												title={t('NOTIFICATION')}>
												<i className="uil-telegram-alt"></i>
											</div>
											<div className="table-action-button table-action-success-button" onClick={() => showPaymentModal(item)}
												title={t('REPLENISH_WALLET')}>
												<i className="uil-money-insert"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'users'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalPayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={topUpBalance} autoComplete="off">
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amount" autoFocus step="any"
								onChange={(e) => setPayment({ ...payment, 'amount': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="comment"
								onChange={(e) => setPayment({ ...payment, 'comment': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => setModalPayment(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!payment.amount}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}

			{/* MODAL NOTIFICATION */}
			<Modal show={showNotificationModal} animation={false} centered dialogClassName="update-modal-width" size="lg"
				backdrop="static" onHide={() => setShowNotificationModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{notification.sendAll ?
							<div>
								{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
							</div>
							:
							<div>
								{t('SEND_PUSH_NOTIFICATION')}
							</div>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: '700px', overflow: 'auto' }}>
					<form onSubmit={() => sendNotification()} autoComplete="off">
						<div className="form-group">
							<label>{t('MESSAGE_TEMPLATES')}<span className="required-mark">*</span></label>
							<Select
								options={messages}
								value={messages.find(option => option.id === notification.messageId) || ''}
								onChange={(option) => selectMessage(option)}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="form-group">
							<label>{t('NAME3')} (ru)<span className="required-mark">*</span></label>
							<input type="text" className="form-control" value={notification.titleRu}
								onChange={(e) => setNotification({ ...notification, 'titleRu': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NAME3')} (uz)<span className="required-mark">*</span></label>
							<input type="text" className="form-control" value={notification.titleUz}
								onChange={(e) => setNotification({ ...notification, 'titleUz': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (ru)<span className="required-mark">*</span></label>
							<Editor
								apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
								onInit={(evt, editor) => editorRefRu.current = editor}
								initialValue={notification.messageRu}
								init={{
									height: 300,
									menubar: true,
									language: 'ru',
									plugins: [
										'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
										'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
										'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
									],
									toolbar: 'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
							/>
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (uz)<span className="required-mark">*</span></label>
							<Editor
								apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
								onInit={(evt, editor) => editorRefUz.current = editor}
								initialValue={notification.messageUz}
								init={{
									height: 300,
									menubar: true,
									language: 'ru',
									plugins: [
										'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
										'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
										'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
										'spellchecker',
									],
									toolbar: 'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help | spellchecker',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
							/>
						</div>
						<p className="text-danger mb-3">
							{t('YOU_ARE_RESPONSIBLE')}
						</p>
						<div>
							<div className="con-upload">
								<div className="con-img-upload">
									{notification.imageUrl &&
										<div className="img-upload">
											<button type="button" className="btn-x-file" onClick={() => setNotification({ ...notification, 'imageUrl': '' })}>
												<i className="uil-times"></i>
											</button>
											<img src={'https://cabinet.cashbek.uz' + notification.imageUrl}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
										</div>
									}
									{!notification.imageUrl &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('IMAGE2')}
											</span>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setShowNotificationModal(false)}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!notification.titleUz || !notification.titleRu}>
								{t('SEND')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL NOTIFICATION */}

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.userId ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => createUpdate()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" value={selectedItem.userName}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'userName': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('PHONE')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" value={selectedItem.userPhone}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'userPhone': e.target.value })}
								disabled={selectedItem.id ? true : false} />
						</div>
						<div className="form-group">
							<label>{t('CARD')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" value={selectedItem.cardNumber}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'cardNumber': e.target.value })}
								disabled={selectedItem.id ? true : false} />
						</div>

						<div className="form-group">
							<label>{t('GENDER')}</label>
							<Select
								options={genders}
								value={genders.find(option => option.id === selectedItem.gender) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'gender': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
