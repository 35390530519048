import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'

// bootstrap
import { Modal, Dropdown } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";

import { menu } from './menu2'

import { POST } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import logo from 'assets/images/logo.svg'
import arrow_down from 'assets/images/icons/arrow_down.svg'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const [modalLogout, setModalLogout] = useState(false)
	const [dropdownIndex, setDropdownIndex] = useState(null)

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function handleMouseOver(index) {
		setDropdownIndex(index)
	}

	function handleMouseOut(index) {
		setDropdownIndex(null)
	}

	function handleClick(item) {
		if (!item?.children?.length) {
			navigate(item.path)
		}
	}

	// function tToggle() {
	// 	var body = document.body;
	// 	body.classList.toggle("vertical-collpsed");
	// 	body.classList.toggle("sidebar-enable");
	// }

	async function logout() {
		await POST('/auth/logout')
		dispatch({ type: 'USER_LOGGED_OUT', payload: null })
		clearTemporaryStorage()
		navigate('/login')
	}

	return (
		<>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link to='/dashboard'>
								<div className="navbar-brand-box">
									<div className="logo-style sm">U</div>
									<div className="logo-style">
										<img src={logo} alt="" />
									</div>
								</div>
							</Link>
						</div>

						{menu.map((item, index) => (
							<div className={"custom-dropdown ms-3 " + (dropdownIndex === index ? 'active ' : '') + (item?.children?.length ? '' : 'me-2')}
								onMouseOver={() => handleMouseOver(index)} onMouseOut={() => handleMouseOut(index)} key={index} onClick={() => handleClick(item)}>
								<div className="custom-dropdown-title" >
									{t(item.title)}
									{item?.children?.length &&
										<img src={arrow_down} alt="" className="custom-dropdown-icon" />
									}
								</div>
								{item?.children?.length &&
									<div className="custom-dropdown-items-back">
										<div className="custom-dropdown-items"
											onMouseOver={() => handleMouseOver(index)} onMouseOut={() => handleMouseOut(index)} >
											{item?.children?.map((item2, index2) => (
												<Link to={item2.path} key={index2}>
													<div className="custom-dropdown-item">
														<i className={'me-3 ' + item2.icon}></i>
														{t(item2.title)}
													</div>
												</Link>
											))
											}
										</div>
									</div>
								}
							</div>
						))
						}
					</div>

					<div className="d-flex">

						<LanguageDropdown />

						<Dropdown className="d-none d-lg-inline-block ms-1">
							<button
								type="button"
								onClick={() => {
									toggleFullscreen();
								}}
								className="btn header-item noti-icon waves-effect"
								data-toggle="fullscreen"
							>
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<div className="d-lg-inline-block ms-1 dropdown" onClick={() => setModalLogout(true)}>
							<button type="button" className="btn header-item noti-icon waves-effect">
								<div className="d-flex">
									<div className="vertical-center me-2">
										{JSON.parse(localStorage.getItem('authUser'))?.username}
									</div>
									<i className="uil-sign-out-alt"></i>
								</div>
							</button>
						</div>

					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
