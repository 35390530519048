import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'

import { GUESTPOST } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz-Latn-UZ.png'
import uzCyrl from 'assets/images/flags/uz-Cyrl-UZ.png'

import LoginBg from 'assets/images/login-bg.svg'

function Login() {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate()

	const [user, setUser] = useState({ "username": "", "password": "" })

	async function handleSubmit(e) {
		e.preventDefault();
		var sendData = {
			username: user.username.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		const response = await GUESTPOST('/auth/login', sendData, { loader: true })
		if (response) {
			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('access_token', response.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem("authUser", JSON.stringify(user))

			navigate("/dashboard")
		}
	}
	function changeLanguage(language = 'uz-Latn-UZ') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz-Latn-UZ') {
			localStorage.setItem('lang', 'uz-Latn-UZ');
		}
		if (language === 'uz-Cyrl-UZ') {
			localStorage.setItem('lang', 'uz-Cyrl-UZ');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'RUSSIAN' }
		else if (locale === 'uz-Latn-UZ') return { flag: uzLatn, lang: 'UZBEK_LATN' }
		else if (locale === 'uz-Cyrl-UZ') return { flag: uzCyrl, lang: 'UZBEK_CYRL' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center bg-white">

					<div className="d-flex justify-content-center align-items-center">
						<div className="auth-card p-4 position-relative">
							<div className="position-absolute lang-position">
								<Dropdown className="cursor-pointer d-flex cursor">
									<Dropdown.Toggle as={LanguageToggle}>
										<div className="d-flex">
											<div className="vertical-center">
												<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
											</div>
											<span>{t(getCurrentLocale().lang)}</span>
										</div>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{i18n.language !== 'ru' &&
											<Dropdown.Item onClick={() => changeLanguage("ru")}>
												<div className="d-flex">
													<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
													<span>{t('RUSSIAN')}</span>
												</div>
											</Dropdown.Item>
										}
										{i18n.language !== 'uz-Latn-UZ' &&
											<Dropdown.Item onClick={() => changeLanguage("uz-Latn-UZ")}>
												<div className="d-flex">
													<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz-Latn-UZ" width="24" height="16" /></div>
													<span>{t('UZBEK_LATN')}</span>
												</div>
											</Dropdown.Item>
										}
										{i18n.language !== 'uz-Cyrl-UZ' &&
											<Dropdown.Item onClick={() => changeLanguage("uz-Cyrl-UZ")}>
												<div className="d-flex">
													<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz-Cyrl-UZ" width="24" height="16" /></div>
													<span>{t('UZBEK_CYRL')}</span>
												</div>
											</Dropdown.Item>
										}
									</Dropdown.Menu>
								</Dropdown>
							</div>

							<div className="p-2">
								{/* <div className="d-flex justify-content-center mb-3">
									<img src={logo} alt="logo" width={200} />
								</div> */}
								<form className="form-horizontal" autoComplete="off" onSubmit={handleSubmit}>

									<div className="d-flex mb-2 justify-content-center">
										<h3>{t('LOGIN_1')}</h3>
									</div>
									<p className="text-center mb-2">{t('LOGIN_2')}</p>

									<div className="form-group">
										<label className="form-label">{t('LOGIN')}<span className="required-mark">*</span></label>
										<input className="form-control"
											autoFocus
											name="username"
											value={user.username}
											onChange={(e) => setUser({ ...user, 'username': e.target.value })} />
									</div>

									<div className="form-group mb-4">
										<label className="form-label">{t('PASSWORD')}<span className="required-mark">*</span></label>
										<input className="form-control"
											type="password"
											name="password"
											value={user.password}
											onChange={(e) => setUser({ ...user, 'password': e.target.value })} />
									</div>

									<div className="text-center">
										<button type="submit" className="w-100 btn btn-primary">
											{t('ENTER')}
										</button>
									</div>
								</form>
							</div>
						</div>
						<div>
							<img src={LoginBg} alt="" className="login-bg" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login;
