import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'

import { Modal } from 'react-bootstrap'
import Pagination from "pages/parts/Pagination"

import { FILE, PGET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const fileRef = useRef(null)

	const [pageCount, setPageCount] = useState(0)

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)

	async function createUpdate() {
		var selectedItemCopy = { ...selectedItem }
		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/services/gocashweb/api/product-category', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/services/gocashweb/api/product-category', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	async function uploadExcel(e) {
		if (e.target?.files[0]) {
			let formData = new FormData();
			formData.append("file", e.target?.files[0]);
			const response = await FILE('/services/gocashweb/api/product-category-import', formData)
			if (response) {
				getData()
			}
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function paginate(data) {
		const response = await PGET('/services/gocashweb/api/product-category-pageList', { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getData() {
		const response = await PGET('/services/gocashweb/api/product-category-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex align-items-center">
					<h4 className="vertical-center me-2">
						{t('CATEGORY')}
					</h4>
					<div>
						<button className="btn btn-outline-primary btn-wide" onClick={() => fileRef.current.click()}>{t('UPLOAD_EXCEL')}</button>
						<input type="file" style={{ visibility: 'hidden' }} ref={fileRef}
							onChangeCapture={uploadExcel}
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
					</div>
				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>

				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME')}(UZ)</th>
									<th>{t('NAME')}(RU)</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.nameUz}</td>
										<td>{item.nameRu}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => createUpdate()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}(UZ)<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.nameUz}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'nameUz': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NAME')}(RU)<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.nameRu}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'nameRu': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
