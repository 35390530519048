import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'

import { GET, POST, PUT, FILE } from 'helpers/api'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const [data, setData] = useState({
		'name': "",
		'description': "",
		'imageUrl': "",
		'posTypeIdList': [],
	})
	const [cashiers, setCashiers] = useState([])

	async function createUpdate() {
		var response;
		if (id) {
			response = await PUT('/services/gocashweb/api/pos-group', data, { loader: true })
			if (response) {
				navigate('/pos-group')
			}
		} else {
			response = await POST('/services/gocashweb/api/pos-group', data, { loader: true })
			if (response) {
				navigate('/pos-group')
			}
		}
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/gocashweb/api/pos-group-image-upload', formData);
		setData({ ...data, 'imageUrl': response.url })
	}

	function onSelectCashier(options) {
		setData({ ...data, 'cashierList': options })
		console.log(options);
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/pos-group/' + id)
		setData(response)

		const temporaryCashierList = await GET('/services/gocashweb/api/cashier-helper')
		setCashiers(temporaryCashierList)
	}

	async function getCashiers() {
		const response = await GET('/services/gocashweb/api/cashier-helper')
		setCashiers(response)
	}

	useEffect(() => {
		if (id) {
			getData()
		} else {
			getCashiers()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{!id ?
						<span>{t('CREATE')}</span>
						:
						<span>{t('EDIT')}</span>
					}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-3">
							<div className="con-upload">
								<div className="con-img-upload">
									{data.imageUrl &&
										<div className="img-upload">
											<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'imageUrl': '' })}>
												<i className="uil-times"></i>
											</button>
											<img src={'https://cabinet.cashbek.uz' + data.imageUrl}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
										</div>
									}
									{!data.imageUrl &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('IMAGE2')}
											</span>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" value={data.name} onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('DESCRIPTION')}</label>
								<input type="text" className="form-control" value={data.description} onChange={(e) => setData({ ...data, 'description': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('CASHIERS')}</label>
								<Select
									value={data.cashierList}
									options={cashiers}
									onChange={(options) => onSelectCashier(options)}
									//onChange={(options) => setData({ ...data, 'cashierList': options })}
									isMulti
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
					</div>

					<div className="btn-wrapper">
						<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/pos-group">{t('CANCEL')}</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide" onClick={() => createUpdate()}>
							{t('SAVE')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate