import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET, POST, PUT } from 'helpers/api'
import { formatMoney, regexPhoneNumber } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)

	async function createUpdate() {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.phone = '998' + selectedItemCopy.phoneNumber
		selectedItemCopy.phoneNumber = '998' + selectedItemCopy.phoneNumber
		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/services/gocashweb/api/favorite-clients', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/services/gocashweb/api/favorite-clients', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		itemCopy.phone = itemCopy.phone.substring(3, 12)
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function changeStatus(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			id: dataCopy[index]['id'],
			activated: e.target.checked,
		}
		await PUT(`/services/gocashweb/api/favorite-clients/activate`, sendData, { loader: true })
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/favorite-clients-pageList', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('FAVORITE_USERS')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('FIO')}</th>
									<th className="text-end">{t('AMOUNT')}</th>
									<th className="text-end">{t('PERCENTAGE')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {regexPhoneNumber(item.phoneNumber)}</td>
										<td className="text-end">{formatMoney(item.amount, 0)}</td>
										<td className="text-end">{item.percentage} {item.percentage && '%'}</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => changeStatus(e, index)} />
												</div>
											</div>
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => { e.preventDefault(); createUpdate() }} autoComplete="off">
						<div className="form-group">
							<label>{t('PHONE')}<span className="required-mark">*</span></label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="text" className="form-control"
									value={selectedItem.phoneNumber}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phoneNumber': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control"
								value={selectedItem.amount}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value, percentage: '' })} />
						</div>
						<div className="form-group">
							<label>{t('PERCENTAGE')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control"
								value={selectedItem.percentage}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'percentage': e.target.value, amount: '' })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
