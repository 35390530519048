import React, { useEffect, useState, useRef } from "react"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { format } from 'date-fns'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'
import { DayOfTheWeek } from "./DayOfTheWeek";
import { toast } from "react-toastify";

function CreateUpdate() {
	const { t } = useTranslation();
	const { posGroudId, id } = useParams();
	const navigate = useNavigate();

	const ymaps = useRef(null);

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			activated: true,
			address: "",
			phone: "",
			regionId: 10,
			cityId: "",
			gpsPointX: 41.311081,
			gpsPointY: 69.240562,
			groupId: posGroudId,
			imageUrl: "",
			posTypeIdList: [],
			posTypeList: [],
			galleryListUrl: [],
		}
	});
	const { errors } = formState

	const [posWorkingDays, setPosWorkingDays] = useState([
		{ days: 1, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 2, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 3, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 4, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 5, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 6, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 7, activated: true, workingStartTime: "09:00", workingEndTime: "18:00", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
	])
	const [regions] = useState([{ "id": 1, "name": "Андижанская область" }, { "id": 2, "name": "Бухарская область" }, { "id": 3, "name": "Джизакская область" }, { "id": 4, "name": "Кашкадарьинская область" }, { "id": 5, "name": "Навоийская область" }, { "id": 6, "name": "Наманганская область" }, { "id": 7, "name": "Самаркандская область" }, { "id": 8, "name": "Сурхандарьинская область" }, { "id": 9, "name": "Сырдарьинская область" }, { "id": 10, "name": "город Ташкент" }, { "id": 11, "name": "Ташкентская область" }, { "id": 12, "name": "Ферганская область" }, { "id": 13, "name": "Хорезмская область" }, { "id": 14, "name": "Респ. Каракалпакстан" }])

	const [activeTab, setActiveTab] = useState(1)

	const [cities, setCities] = useState([])
	const [posTypeList, setPosTypeList] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [data, setData] = useState({
		activated: true,
		address: "",
		phone: "",
		regionId: 10,
		cityId: "",
		gpsPointX: 41.311081,
		gpsPointY: 69.240562,
		groupId: posGroudId,
		imageUrl: "",
		posTypeIdList: [],
		posTypeList: [],
		galleryListUrl: [],
		cashiersList: [],
		firstVisitBonus: false,
		firstVisitPercentage: "",
		firstVisitAmount: "",
		chequeTotalAmountRestrictionFlag: false,
		chequeTotalAmountRestriction: '',
		writeOffPercentageFlag: false,
		writeOffPercentage: '',
		writeOffAmount: '',
	})
	const defaultState = {
		center: [41.311081, 69.240562],
		zoom: 11,
		controls: []
	};

	function fileUpload(e, name) {
		let formData = new FormData();
		var reader = new FileReader();
		var file = e.target.files[0];
		reader.readAsDataURL(file);
		reader.onload = function (e) {
			var image = new Image();
			image.src = e.target.result;
			image.onload = async function () {
				var height = this.height;
				var width = this.width;
				if (height !== width) {
					alert("Фотография должна быть в соотношение 1:1");
					return false;
				}
				formData.append("image", file);
				const response = await FILE('/services/gocashweb/api/pos-image-upload', formData);
				if (name === 'imageUrl') {
					setValue('imageUrl', response[0])
					setData({ ...data, 'imageUrl': response[0] })
				} else {
					setValue('logoUrl', response[0])
					setData({ ...data, 'logoUrl': response[0] })
				}
			};
		};
	}

	async function multiFileUpload(e) {
		let formData = new FormData();
		for (let i = 0; i < e.target.files.length; i++) {
			formData.append('image', e.target.files[i])
		}

		if (formData.get('image')) {
			const response = await FILE('/services/gocashweb/api/pos-image-upload', formData);
			var galleryListUrl = []
			for (let i = 0; i < response.length; i++) {
				galleryListUrl.push(response[i]);
			}
			formData.delete('image')

			setValue('galleryListUrl', galleryListUrl)
			setData({ ...data, 'galleryListUrl': galleryListUrl })
		}
	}

	function deleteFromGalleryListUrl(index) {
		var dataCopy = { ...data }
		dataCopy.galleryListUrl.splice(index, 1);
		setData(dataCopy)
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.gpsPointX = data.gpsPointX
		sendData.gpsPointY = data.gpsPointY
		sendData.galleryListUrl = data.galleryListUrl
		sendData.imageUrl = data.imageUrl
		sendData.logoUrl = data.logoUrl
		sendData.cashiersList = data.cashiersList
		// 
		sendData.firstVisitBonus = data.firstVisitBonus
		sendData.firstVisitAmount = data.firstVisitAmount
		sendData.firstVisitPercentage = data.firstVisitPercentage
		// 
		sendData.chequeTotalAmountRestrictionFlag = data.chequeTotalAmountRestrictionFlag
		sendData.chequeTotalAmountRestriction = data.chequeTotalAmountRestriction
		// 
		sendData.writeOffPercentageFlag = data.writeOffPercentageFlag
		sendData.writeOffPercentage = data.writeOffPercentage
		sendData.writeOffAmount = data.writeOffAmount

		console.log(sendData);
		sendData.workingDays = posWorkingDays

		var response;
		if (sendData.id) {
			response = await PUT('/services/gocashweb/api/pos', sendData, { loader: true })
			if (response) {
				navigate(-1)
			}
		} else {
			response = await POST('/services/gocashweb/api/pos', sendData, { loader: true })
			if (response) {
				navigate(-1)
			}
		}
	}

	async function selectRegion(option) {
		setValue('regionId', option.id)
		setValue('cityId', "")
		const response = await GET('/services/gocashweb/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/pos/' + id)

		var date = new Date();
		var posWorkingDaysCopy = [...posWorkingDays]
		console.log(posWorkingDaysCopy);
		for (let i = 0; i < response.workingDays.length; i++) {

			const start = response.workingDays[i]['workingStartTime']?.split(':');
			const end = response.workingDays[i]['workingEndTime']?.split(':');
			if (start?.length > 0) {
				var startFormatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), start[0], start[1], 0, 0);
			}

			if (end?.length > 0) {
				var endFormatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), end[0], end[1], 0, 0);
			}

			posWorkingDaysCopy[i]['activated'] = response?.workingDays[i]?.activated
			posWorkingDaysCopy[i]['startTime'] = startFormatted
			posWorkingDaysCopy[i]['endTime'] = endFormatted
			posWorkingDaysCopy[i]['workingStartTime'] = response.workingDays[i]['workingStartTime']
			posWorkingDaysCopy[i]['workingEndTime'] = response.workingDays[i]['workingEndTime']
		}
		setPosWorkingDays(posWorkingDaysCopy)

		const temporaryCities = await GET('/services/gocashweb/api/city-helper/' + response.regionId)
		setCities(temporaryCities)

		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
		setData({ ...data, ...response })
	}

	function renderWeekName(id) {
		for (let i = 0; i < DayOfTheWeek.length; i++) {
			if (DayOfTheWeek[i]['id'] === id) {
				return DayOfTheWeek[i]['name' + localStorage.getItem('i18nextLng').split('-').join('')]
			}
		}
	}

	function selectTime(time, index, type) {
		var posWorkingDaysCopy = [...posWorkingDays]
		if (type === 'startTime') {
			posWorkingDaysCopy[index]['startTime'] = time
			posWorkingDaysCopy[index]['workingStartTime'] = format(new Date(time), 'HH:mm')
		} else {
			posWorkingDaysCopy[index]['endTime'] = time
			posWorkingDaysCopy[index]['workingEndTime'] = format(new Date(time), 'HH:mm')
		}
		setPosWorkingDays(posWorkingDaysCopy)
	}

	function setDaysActivated(index) {
		var posWorkingDaysCopy = [...posWorkingDays]
		posWorkingDaysCopy[index]['activated'] = !posWorkingDaysCopy[index]['activated']
		setPosWorkingDays(posWorkingDaysCopy)
	}

	async function getPosTypeList() {
		const response = await GET('/services/gocashweb/api/pos-type-helper')
		setPosTypeList(response)
	}

	async function getCities() {
		const response = await GET('/services/gocashweb/api/city-helper/' + getValues('regionId'))
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getCashiers() {
		const response = await GET('/services/gocashweb/api/cashier-helper')
		if (response) {
			setCashiers(response)
		}
	}

	useEffect(() => {
		getPosTypeList()
		getCashiers()
		if (id) {
			getData()
		} else {
			getCities()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	function copyText() {
		navigator.clipboard.writeText(data.apiKey)
		toast.success(t('copied'))
	}

	return (
		<>
			<div className="page-header justify-content-between d-flex mb-2">
				<h4 className="vertical-center">
					{t('POS')}
				</h4>
				{data.apiKey &&
					<h5 className="d-flex align-items-center cursor" onClick={() => copyText()}>
						apiKey:
						<p className="ms-2" style={{ fontFamily: 'monospace' }}>
							{data.apiKey}
						</p>
					</h5>
				}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="pos-tabs">
						<div className={"pos-tab " + (activeTab === 1 ? 'active' : '')} onClick={() => setActiveTab(1)}>
							{t('GENERAL')}
						</div>
						<div className={"pos-tab " + (activeTab === 2 ? 'active' : '')} onClick={() => setActiveTab(2)}>
							{t('ADDITIONAL_INFO')}
						</div>
						{/* <div className={"pos-tab " + (activeTab === 2 ? 'active' : '')} onClick={() => setActiveTab(2)}>
							{t('GALLERY')}
						</div> */}
						<div className={"pos-tab-indicator" + activeTab} />
					</div>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						{activeTab === 1 &&
							<div className="row ">
								<div className="col-md-4">
									<div className="d-flex justify-content-between mb-5">
										<div className="me-5">
											<label>{t('LOGO')}</label>
											<div className="con-upload rounded d-flex">
												<div className="con-img-upload">
													{data.logoUrl &&
														<div className="img-upload">
															<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'logoUrl': '' })}>
																<i className="uil-times"></i>
															</button>
															<img src={'https://cabinet.cashbek.uz' + data.logoUrl}
																style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
														</div>
													}
													{!data.logoUrl &&
														<div className="con-input-upload">
															<input type="file" onChange={(e) => fileUpload(e, 'logoUrl')} />
															<span className="text-input fz14">
																{t('IMAGE2')}
															</span>
														</div>
													}
												</div>
											</div>
										</div>
										<div className="w-75">
											<label>{t('IMAGE2')}</label>
											<div className="con-upload">
												<div className="con-img-upload">
													{data.imageUrl &&
														<div className="img-upload">
															<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'imageUrl': '' })}>
																<i className="uil-times"></i>
															</button>
															<img src={'https://cabinet.cashbek.uz' + data.imageUrl}
																style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
														</div>
													}
													{!data.imageUrl &&
														<div className="con-input-upload">
															<input type="file" onChange={(e) => fileUpload(e, 'imageUrl')} />
															<span className="text-input fz14">
																{t('IMAGE2')}
															</span>
														</div>
													}
												</div>
											</div>
										</div>
									</div>
									<div className="d-flex">
										<div className="w-100 me-3">
											<div className="form-group">
												<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
												<input type="text" className="form-control" name="name" {...register('name')} />
												<span className="text-danger text-sm">{errors.name?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('PHONE')}<span className="required-mark">*</span></label>
												<input type="number" className="form-control" name="phone" {...register('phone')} />
												<span className="text-danger text-sm">{errors.phone?.message}</span>
											</div>
											<div className="form-group">
												<label>Service ID</label>
												<input type="number" className="form-control" name="serviceId" {...register('serviceId')} />
											</div>
										</div>
										<div className="w-100 ms-3">
											<div className="form-group">
												<label>{t('ADDRESS')}</label>
												<input type="text" className="form-control" name="address" {...register('address')} />
												<span className="text-danger text-sm">{errors.address?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('REGION')}<span className="required-mark">*</span></label>
												<Controller
													name="regionId"
													control={control}
													render={({ field }) =>
														<Select
															{...field}
															options={regions}
															value={regions.find(option => option.id === getValues('regionId')) || ''}
															onChange={(option => selectRegion(option))}
															placeholder=""
															noOptionsMessage={() => t('LIST_IS_EMPTY')}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
														/>
													}
												/>
												<span className="text-danger text-sm">{errors?.regionId?.message}</span>
											</div>
											<div className="form-group">
												<label>{t('CITY')}<span className="required-mark">*</span></label>
												<Controller
													name="cityId"
													control={control}
													render={({ field }) =>
														<Select
															{...field}
															options={cities}
															value={cities.find(option => option.id === getValues('cityId')) || ''}
															onChange={(e) => setValue('cityId', e.id)}
															placeholder=""
															noOptionsMessage={() => t('LIST_IS_EMPTY')}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
														/>
													}
												/>
											</div>
											<span className="text-danger text-sm">{errors?.cityId?.message}</span>
										</div>
									</div>
								</div>
								<div className="col-md-8">
									<div className="mb-3" style={{ height: '480px' }}>
										<YMaps
											query={{
												load: "package.full",
												apikey: 'b4de6db2-b765-41e3-9376-c9bd74b17a18'
											}}
										>
											<Map
												defaultOptions={{
													copyrightUaVisible: false,
													yandexMapDisablePoiInteractivity: true,
												}}
												style={{ height: '480px' }}
												defaultState={defaultState}
												onLoad={(e) => {
													ymaps.current = e;
												}}
												onClick={(event) => {
													const coords = event.get("coords");
													ymaps.current.geocode(coords).then(() => {
														setData({ ...data, 'gpsPointX': coords[0], 'gpsPointY': coords[1] })
													});
												}}>
												<Placemark geometry={[data.gpsPointX, data.gpsPointY]} />
											</Map>
										</YMaps>
									</div>
								</div>
							</div>
						}

						{activeTab === 2 &&
							<div className="row mb-5">
								<div className="col-md-5 mb-3">
									<div className="form-group">
										<div className="table-responsive">
											<table className="table table-striped mb-0">
												<thead>
													<tr>
														<th></th>
														<th>{t('DAYS_OF_THE_WEEK')}</th>
														<th>{t('OPENING_TIME')}</th>
														<th>{t('CLOSING_TIME')}</th>
													</tr>
												</thead>
												<tbody>
													{posWorkingDays.map((item, index) => (
														<tr key={index}>
															<td>
																<input type="checkbox" className="form-check-input"
																	checked={item.activated}
																	onChange={() => setDaysActivated(index)} />
															</td>
															<td>
																{renderWeekName(item.days)}
															</td>
															<td>
																{item.activated ?
																	<DatePicker
																		className="form-control text-center w-50 h-25"
																		selected={posWorkingDays[index]['startTime'] ? new Date(posWorkingDays[index]['startTime']) : ''}
																		onChange={(date) => selectTime(date, index, 'startTime')}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={30}
																		timeCaption="Время"
																		timeFormat="HH:mm"
																		dateFormat="HH:mm" />
																	:
																	<span>-</span>
																}
															</td>
															<td>
																{item.activated ?
																	<DatePicker
																		className="form-control text-center w-50 h-25"
																		selected={posWorkingDays[index]['endTime'] ? new Date(posWorkingDays[index]['endTime']) : ''}
																		onChange={(date) => selectTime(date, index, 'endTime')}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={30}
																		timeCaption="Время"
																		timeFormat="HH:mm"
																		dateFormat="HH:mm" />
																	:
																	<span>-</span>
																}
															</td>
														</tr>
													))
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="col-md-7 mb-3">
									<div className="row">
										{data.galleryListUrl.map((item, index) => (
											<div className="col-md-4" key={index}>
												<div className="con-upload">
													<div className="con-img-upload">
														{data.galleryListUrl.length > 0 &&
															<div className="img-upload">
																<button type="button" className="btn-x-file" onClick={() => deleteFromGalleryListUrl(index)}>
																	<i className="uil-times"></i>
																</button>
																<img src={'https://cabinet.cashbek.uz' + data.galleryListUrl[index]}
																	style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
															</div>
														}
													</div>
												</div>
											</div>
										))}
										<div className="col-md-4">
											<div className="con-upload">
												<div className="con-img-upload">
													<div className="con-input-upload">
														<input type="file" multiple onChange={(e) => multiFileUpload(e)} />
														<span className="text-input fz14">{t('IMAGE2')}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-2">
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('FIRST_VISIT_BONUS')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="firstVisitBonus"
												checked={data?.firstVisitBonus}
												onChange={(e) => setData({ ...data, 'firstVisitBonus': e.target.checked })} />
										</div>
									</div>
									{data.firstVisitBonus &&
										<>
											<div className="form-group mb-2">
												<label className="fw-600 vertical-center">{t('FIRST_VISIT_BONUS_PERCENTAGE')}</label>
												<input type="number" className="form-control w-100" name="firstVisitPercentage" value={data.firstVisitPercentage}
													onChange={(e) => setData({ ...data, 'firstVisitPercentage': e.target.value, 'firstVisitAmount': '' })} />
											</div>
											<div className="form-group mb-2">
												<label className="fw-600 vertical-center">{t('FIRST_VISIT_BONUS_AMOUNT')}</label>
												<input type="number" className="form-control w-100" name="firstVisitAmount" value={data.firstVisitAmount}
													onChange={(e) => setData({ ...data, 'firstVisitAmount': e.target.value, 'firstVisitPercentage': '' })} />
											</div>
										</>
									}
								</div>

								<div className="col-md-2">
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('MAXIMUM_CHECK_AMOUNT')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="chequeTotalAmountRestrictionFlag"
												checked={data?.chequeTotalAmountRestrictionFlag}
												onChange={(e) => setData({ ...data, 'chequeTotalAmountRestrictionFlag': e.target.checked })} />
										</div>
									</div>
									{data.chequeTotalAmountRestrictionFlag &&
										<>
											<div className="form-group mb-2">
												<label className="fw-600 vertical-center">{t('MAXIMUM_CHECK_AMOUNT')}</label>
												<input type="number" className="form-control w-100" name="chequeTotalAmountRestriction" value={data.chequeTotalAmountRestriction}
													onChange={(e) => setData({ ...data, 'chequeTotalAmountRestriction': e.target.value, })} />
											</div>
										</>
									}
								</div>

								<div className="col-md-2">
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('limiting_payment_by_accumulated_points')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="writeOffPercentageFlag"
												checked={data?.writeOffPercentageFlag}
												onChange={(e) => setData({ ...data, 'writeOffPercentageFlag': e.target.checked })} />
										</div>
									</div>
									{data.writeOffPercentageFlag &&
										<>
											<div className="form-group mb-2">
												<label className="fw-600 vertical-center">{t('PAYMENT_LIMIT_PERCENTAGE')}</label>
												<input type="number" className="form-control w-100" name="writeOffPercentage" value={data.writeOffPercentage}
													onChange={(e) => setData({ ...data, 'writeOffPercentage': e.target.value, 'writeOffAmount': '' })} />
											</div>
											<div className="form-group mb-2">
												<label className="fw-600 vertical-center">{t('PAYMENT_LIMIT_AMOUNT')}</label>
												<input type="number" className="form-control w-100" name="writeOffAmount" value={data.writeOffAmount}
													onChange={(e) => setData({ ...data, 'writeOffAmount': e.target.value, 'writeOffPercentage': '' })} />
											</div>
										</>
									}
								</div>

								<div className="col-md-6">
									<div className="d-flex gap-3">
										<div className="form-group w-100">
											<label>{t('ACTIVITIES')}</label>
											<Select
												options={posTypeList}
												value={posTypeList.find(option => option.id === getValues('categoryId')) || ''}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group w-100">
											<label>Telegram</label>
											<input type="text" className="form-control" name="telegram" {...register('telegram')} />
										</div>

										<div className="form-group w-100">
											<label>Instagram</label>
											<input type="text" className="form-control" name="instagram" {...register('instagram')} />
										</div>

										<div className="form-group w-100">
											<label>Facebook</label>
											<input type="text" className="form-control" name="facebook" {...register('facebook')} />
										</div>
									</div>

									<div className="form-group w-100">
										<label>{t('CASHIERS')}</label>
										<Select
											options={cashiers}
											value={data.cashiersList}
											isMulti
											isClearable={false}
											onChange={(options) => setData({ ...data, cashiersList: options })}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								</div>
							</div>
						}

						<div className="btn-wrapper">
							<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/pos">{t('CANCEL')}</Link>
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

