import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
//import { useSelector } from 'react-redux'

import { PGET } from 'helpers/api'

function FilterPagination({ pagination = {}, paginate }) {
	//const settings = useSelector(state => state.settings)

	const [pageCount, setPageCount] = useState(0)

	async function getCurrentPage(pageNumber) {
		const response = await PGET(pagination.url, { page: pageNumber.selected, size: 20 }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / 20))
			paginate(response.data)
		}
	}

	useEffect(() => {
		if (pagination?.response && pagination.response.headers["x-total-count"]) {
			setPageCount(Math.ceil(pagination.response.headers['x-total-count'] / 20))
		}
	}, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{pageCount > 1 &&
				<ReactPaginate
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={3}
					pageRangeDisplayed={3}
					onPageChange={getCurrentPage}
					containerClassName={'pagination'}
					activeClassName={'active'}
					pageClassName={'page-item'}
				/>
			}
		</>
	)
}

export default FilterPagination
