import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Editor } from '@tinymce/tinymce-react';
import { Modal } from "react-bootstrap"
import { useLocation } from 'react-router-dom';

import { FILE, POST, PUT } from 'helpers/api';

function MessageModal({ notification, setNotification, showNotificationModal, setShowNotificationModal, complete }) {
	const { t } = useTranslation()

	const location = useLocation();

	const editorRefRu = useRef(null);
	const editorRefUz = useRef(null);

	const [agreement, setAgreement] = useState(false)

	async function saveNotification(e) {
		e.preventDefault();
		var notificationCopy = { ...notification }
		if (editorRefUz.current) {
			notificationCopy.messageUz = editorRefUz.current.getContent();
		}
		if (editorRefRu.current) {
			notificationCopy.messageRu = editorRefRu.current.getContent();
		}
		var response;
		if (notificationCopy.messageId) {
			if (location.pathname !== "/analytics/rfm") {
				response = await PUT('/services/gocashweb/api/messages', notificationCopy)
			}
		} else {
			response = await POST('/services/gocashweb/api/messages', notificationCopy)
		}

		notificationCopy.messageId = response?.id
		complete(notificationCopy)
	}

	function closeModal() {
		setNotification({
			'titleUz': '',
			'titleRu': '',
			'messageUz': '',
			'messageRu': '',
			'imageUrl': '',
		})
		setShowNotificationModal(false)
	}

	function fileUpload(e) {
		let formData = new FormData();
		var reader = new FileReader();
		var file = e.target.files[0];
		if (file) {
			reader.readAsDataURL(file);
			reader.onload = function (e) {
				var image = new Image();
				image.src = e.target.result;
				image.onload = async function () {
					formData.append("image", file);
					const response = await FILE('/services/gocashweb/api/pos-image-upload', formData);
					setNotification({ ...notification, 'imageUrl': response[0] })
				};
			};
		}
	}

	return (
		<>
			{/* MODAL NOTIFICATION */}
			<Modal show={showNotificationModal} animation={false} centered dialogClassName="update-modal-width" size="lg"
				backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('NOTIFICATION')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: '650px', overflow: 'auto' }}>
					<form onSubmit={(e) => saveNotification(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME3')} (ru)<span className="required-mark">*</span></label>
							<input type="text" className="form-control" value={notification.titleRu}
								onChange={(e) => setNotification({ ...notification, 'titleRu': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NAME3')} (uz)<span className="required-mark">*</span></label>
							<input type="text" className="form-control" value={notification.titleUz}
								onChange={(e) => setNotification({ ...notification, 'titleUz': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (ru)<span className="required-mark">*</span></label>
							<Editor
								apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
								onInit={(evt, editor) => editorRefUz.current = editor}
								initialValue={notification.messageRu}
								init={{
									height: 300,
									menubar: true,
									language: 'ru',
									plugins: [
										'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
										'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
										'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
									],
									toolbar: 'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
							/>
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (uz)<span className="required-mark">*</span></label>
							<Editor
								apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
								onInit={(evt, editor) => editorRefRu.current = editor}
								initialValue={notification.messageUz}
								init={{
									height: 300,
									menubar: true,
									language: 'ru',
									plugins: [
										'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
										'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
										'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
									],
									toolbar: 'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
							/>
						</div>
						<div>
							<div className="con-upload">
								<div className="con-img-upload">
									{notification.imageUrl &&
										<div className="img-upload">
											<button type="button" className="btn-x-file" onClick={() => setNotification({ ...notification, 'imageUrl': '' })}>
												<i className="uil-times"></i>
											</button>
											<img src={'https://cabinet.cashbek.uz' + notification.imageUrl}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
										</div>
									}
									{!notification.imageUrl &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('IMAGE2')}
											</span>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="d-flex align-items-center mb-3">
							<input
								type="checkbox"
								className="form-check-input me-2"
								checked={agreement}
								onChange={(e) => setAgreement(e.target.checked)}
								id="agreement"
							/>
							<label htmlFor="agreement" className="text-danger mt-1 mb-0">
								{t('YOU_ARE_RESPONSIBLE')}
							</label>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							{location.pathname === "/analytics/rfm" &&
								<button type="submit" className="btn btn-primary w-100"
									disabled={!notification.titleUz || !notification.titleRu || !agreement}>
									{t('SEND')}
								</button>
							}
							{location.pathname !== "/analytics/rfm" &&
								<button type="submit" className="btn btn-primary w-100"
									disabled={!notification.titleUz || !notification.titleRu}>
									{t('SAVE')}
								</button>
							}
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL NOTIFICATION */}
		</>
	)
}

export default MessageModal