import React from 'react'
import { Outlet } from 'react-router-dom';

import Header from "./Header";
import Sidebar from "./Sidebar";

function Layout() {

	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />
				<div className="main-content">
					<div className="page-content">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}

export default Layout
