import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Provider } from "react-redux"
import { throttle } from 'lodash';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

import store from "./store"
import { saveState } from './store/localStorage'

// import * as Yup from 'yup';
// import { yup_ru } from 'locales/yup/yup_ru';
// import { yup_uzCyrl } from 'locales/yup/yup_uzCyrl';
// import { yup_uzLatn } from 'locales/yup/yup_uzLatn';

// if(localStorage.getItem('I18N_LANGUAGE') === 'ru') {
// 	Yup.setLocale(yup_ru);
// }
// if(localStorage.getItem('I18N_LANGUAGE') === 'uz-Cyrl-UZ') {
// 	Yup.setLocale(yup_uzCyrl);
// }
// if(localStorage.getItem('I18N_LANGUAGE') === 'uz-Latn-UZ') {
// 	Yup.setLocale(yup_uzLatn);
// }

store.subscribe(throttle(() => {
	saveState(store.getState())
}, 1000));

registerLocale('ru', ru)
setDefaultLocale('ru', ru)


const app = (
	<Provider store={store}>
		<App />
	</Provider>
)

ReactDOM.render(app, document.getElementById("root"))