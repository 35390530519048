import React, { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { GET, POST, PUT } from 'helpers/api'

function CreateUpdate() {
	const { id } = useParams();
	const { t } = useTranslation()
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			imageUrl: '',
			productName: '',
			barcode: '',
			brandName: '',
			manufacturerName: '',
			unitList: [],
			uomId: 1,
			categoryId: 2,
			seasonal: 0,
		}
	});
	const { errors } = formState
	const [categories, setCategories] = useState([])
	const [uoms, setUoms] = useState([])

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		if (sendData.id) {
			response = await PUT('/services/gocashweb/api/product', sendData, { loader: true })
		} else {
			response = await POST('/services/gocashweb/api/product', sendData, { loader: true })
		}

		if (response) {
			navigate('/products')
		}
	}

	function generateRandomBarcode() {
		setValue('barcode', Math.floor(Math.random() * 899999 + 100000))
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/product/' + id, {}, { loader: true })
		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
	}

	async function getCategories() {
		const response = await GET('/services/gocashweb/api/product-category-helper')
		setCategories(response)
	}

	async function getUoms() {
		const response = await GET('/services/gocashweb/api/product-uom-helper')
		setUoms(response)
	}

	useEffect(() => {
		if (id) {
			getData()
			getCategories()
			getUoms()
		} else {
			getCategories()
			getUoms()
			if (reduxSettings.categoryId) {
				setValue('categoryId', reduxSettings.categoryId)
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('PRODUCT')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						{!id ?
							<h6>{t('CREATE')}</h6>
							:
							<h6>{t('EDIT')}</h6>
						}
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>{t("NAME_OF_PRODUCT")}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" tabIndex="1" name="name" {...register('productName')} autoFocus />
									<span className="text-danger text-sm">{errors.productName?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('BARCODE')}</label>
									<div className="position-relative">
										<input type="number" className="form-control" tabIndex="2" name="barcode" disabled={id} {...register('barcode')} />
										<span className="text-danger text-sm">{errors.barcode?.message}</span>
										<i className="uil-sync input-right-icon" onClick={() => generateRandomBarcode()}></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t('ARTIKUL')}</label>
									<div className="position-relative">
										<input type="number" className="form-control" tabIndex="3" name="artikul" disabled={id} {...register('artikul')} />
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>{t('CATEGORY')}<span className="required-mark">*</span></label>
									<Controller

										name="categoryId"
										control={control}
										render={({ field }) =>
											<Select
												tabIndex="4"
												{...field}
												classNamePrefix="react-select"
												options={categories}
												value={categories.find(option => option.id === getValues('categoryId'))}
												onChange={(e) => setValue('categoryId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										}
									/>
									<span className="text-danger text-sm">{errors.categoryId?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('UNIT_OF_MEASUREMENT')}<span className="required-mark">*</span></label>
									<Controller
										name="uomId"
										control={control}
										render={({ field }) =>
											<Select
												//isDisabled={id}
												tabIndex="5"
												{...field}
												options={uoms}
												value={uoms.find(option => option.id === getValues('uomId'))}
												onChange={(e) => setValue('uomId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										}
									/>
									<span className="text-danger text-sm">{errors.uomId?.message}</span>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>{t('PRICE')}</label>
									<input type="number" tabIndex="6" className="form-control" name="price" {...register('price')} />
									<span className="text-danger text-sm">{errors.price?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('SALE_PRICE')}</label>
									<input type="number" tabIndex="7" className="form-control" name="salePrice" {...register('salePrice')} />
									<span className="text-danger text-sm">{errors.salePrice?.message}</span>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/products">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide" tabIndex="4" onClick={handleSubmit(createUpdate)}>{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate
