import React from 'react' // eslint-disable-line no-unused-vars

function Update() {
	return (
		<div className="card">
			<div className="card-body text-center">
				<div className="fz-30 my-5">
					<b>ОБНОВЛЯЕМ ФУНКЦИОНАЛ</b>
				</div>
			</div>
		</div>
	)
}

export default Update