import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GET, PUT } from 'helpers/api';

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState({})

	async function save(e) {
		e.preventDefault();

		await PUT('/services/gocashweb/api/owner-settings', data, { loader: true })
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/owner-settings')
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []);

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('POS_GROUP_SETTINGS')}
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={(e) => save(e)}>
						<div className="row">
							<div className="col-md-4">
								<div className="d-flex justify-content-between mb-3">
									<p className="fw-600 vertical-center">{t('cancellation_points_for_inactivity')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="bonusesTtlFLag"
											checked={data?.bonusesTtlFLag}
											onChange={(e) => setData({ ...data, 'bonusesTtlFLag': e.target.checked })} />
									</div>
								</div>
								{data.bonusesTtlFLag &&
									<>
										<div className="form-group d-flex justify-content-between mb-2">
											<p className="fw-600 vertical-center">{t('number_of_days_until_points_are_canceled_for_inactivity')}</p>
											<input type="number" className="form-control small_input" name="bonusesTtlInDays" value={data.bonusesTtlInDays}
												onChange={(e) => setData({ ...data, 'bonusesTtlInDays': e.target.value, })} />
										</div>
									</>
								}
							</div>

							<div className="col-md-4">
								<div className="d-flex justify-content-between mb-3">
									<p className="fw-600 vertical-center">{t('FUNDED_PROGRAM_BY_BIRTHDAY')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="birthdayProgramFlag"
											checked={data?.birthdayProgramFlag}
											onChange={(e) => setData({ ...data, 'birthdayProgramFlag': e.target.checked })} />
									</div>
								</div>


								{data.birthdayProgramFlag &&
									<>
										<div className="d-flex justify-content-between mb-3">
											<p className="fw-600 vertical-center">{t('birthdayInstantBalanceReplenishment')}</p>
											<div className="form-check form-switch form-switch-lg">
												<input type="checkbox" className="form-check-input" name="birthdayInstantBalanceReplenishment"
													checked={data?.birthdayInstantBalanceReplenishment}
													onChange={(e) => setData({ ...data, 'birthdayInstantBalanceReplenishment': e.target.checked })} />
											</div>
										</div>

										{data.birthdayInstantBalanceReplenishment &&
											<>
												<div className="form-group d-flex justify-content-between mb-2">
													<p className="fw-600 vertical-center">{t('birthdayInstantBalanceReplenishmentValue')}</p>
													<input type="number" className="form-control small_input" name="birthdayInstantBalanceReplenishmentValue" value={data.birthdayInstantBalanceReplenishmentValue}
														onChange={(e) => setData({ ...data, 'birthdayInstantBalanceReplenishmentValue': e.target.value, })} />
												</div>
											</>
										}
										{!data.birthdayInstantBalanceReplenishment &&
											<>
												<div className="form-group d-flex justify-content-between mb-2">
													<p className="fw-600 vertical-center">{t('birthdayPercentageAward')}</p>
													<input type="number" className="form-control small_input" name="birthdayPercentageAward" value={data.birthdayPercentageAward}
														onChange={(e) => setData({ ...data, 'birthdayPercentageAward': e.target.value, 'birthdayAmountAward': '' })} />
												</div>

												<div className="form-group d-flex justify-content-between mb-2">
													<p className="fw-600 vertical-center">{t('birthdayAmountAward')}</p>
													<input type="number" className="form-control small_input" name="birthdayAmountAward" value={data.birthdayAmountAward}
														onChange={(e) => setData({ ...data, 'birthdayAmountAward': e.target.value, 'birthdayPercentageAward': '' })} />
												</div>
											</>
										}
									</>
								}
							</div>
						</div>

						<div className="btn-wrapper">
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Index