import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
// import { toast } from 'react-toastify'
import XLSX from 'xlsx'

import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatDate, returnSign, findFromArrayById, regexPhoneNumber, formatMoney, formatDateWithTime, formatDateBackend } from 'helpers/helpers'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)

	const [filterData, setFilterData] = useState({
		posId: null,
		from: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		to: formatDateBackend(new Date()),
		gender: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/gocashweb/api/users-pageList',
		response: null
	})


	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/services/gocashweb/api/payment-report-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.from)
			urlParams += returnSign(urlParams) + 'from=' + filterData.from
		if (filterData.to)
			urlParams += returnSign(urlParams) + 'to=' + filterData.to
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/gocashweb/api/payment-report-pageList" + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/gocashweb/api/clients-info-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[
				t('CLIENT'), t('PHONE'), t('BIRTH_DAY'), t('AGE'), t('GENDER'),
				t('STATUS'), t('LAST_ACTIVITY')
			]]
			const response = await GET(filterUrl)

			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].clientName)
				excelItems.push(response[i].clientLogin)
				excelItems.push(response[i].birthDate)
				excelItems.push(response[i].age)
				excelItems.push(response[i].gender)
				excelItems.push(response[i].clientStatusName)
				excelItems.push(response[i].lastChequeDate)
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('CLIENTS') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
		}

		setDropdownIsOpen(false)
		setFilterData({ ...filterData, 'urlParams': urlParams })
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.ageFrom === null &&
			filterData.ageTo === null &&
			filterData.gender === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			ageFrom: null,
			ageTo: null,
			gender: null,
			search: ''
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET('/services/gocashweb/api/payment-report-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': '/services/gocashweb/api/payment-report-pageList', 'response': response })
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/gocashweb/api/pos-helper', {}, {})
		if (response) {
			setPoses(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PAYMENT_HISTORY')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.from ? new Date(filterData.from) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'from': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.to ? new Date(filterData.to) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'to': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS_GROUP')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{(filterData.from || filterData.to) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.from &&
											<span>{formatDate(filterData.from)}</span>
										}
										{(filterData.from && filterData.to) &&
											<span>|</span>
										}
										{filterData.to &&
											<span>{formatDate(filterData.to)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('CLIENT')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('COMMENT')}</th>
									<th className="text-center">{t('DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.userName}</td>
										<td className="text-center">{regexPhoneNumber(item.userNumber)}</td>
										<td className="text-center">{formatMoney(item.amount)}</td>
										<td className="text-center">{item.comment}</td>
										<td className="text-center">{formatDateWithTime(item.paymentDateTime)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index