import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { GET } from 'helpers/api';
import { formatMoney } from 'helpers/helpers';

function Loyalty() {
	const { t } = useTranslation()

	const [data, setData] = useState([])


	async function getData() {
		const response = await GET('/services/gocashweb/api/loyalty-indicator?year=2023&posId=68')
		if (response?.length > 0) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []);

	return (
		<>
			<div>
				<h4 className="vertical-center">
					{t('LOYALTY_INDICATOR')}
				</h4>
			</div>

			<div className="table-responsive analytics-table">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>{t('MONTH')}</th>
							<th>{t('CLIENT_BASE')}</th>
							<th>{t('CLIENT_BASE_GROWTH')}</th>
							<th>{t('ACTIVE_CLIENTS')}</th>
							<th>{t('PENETRATION')}</th>
							<th>{t('ALL_AVG_PROPORTION')}</th>
							<th className="text-end">{t('Churn Rate')}</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => (
							<tr key={index}>
								<td>
									{t(`MONTH_${item.month}`)}
								</td>
								<td>{item.clientsBase ?? '--'}</td>
								<td>{formatMoney(item.clientsBaseGrowth) ?? '--'}</td>
								<td>{formatMoney(item.activeClients) ?? '--'}</td>
								<td>{formatMoney(item.penetration) ?? '--'}</td>
								<td>{formatMoney(item.allAvgAndMonthAvgProportion) ?? '--'}</td>
								<td className="text-end">{formatMoney(item.churnRate)}</td>
							</tr>
						))
						}
					</tbody>
				</table>
			</div>
		</>
	)
}

export default Loyalty