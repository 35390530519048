import React, { useEffect } from 'react'

import logo from 'assets/images/logo.svg'

import play_market from 'assets/images/icons/play_market.png'
import app_store from 'assets/images/icons/app_store.svg'

function QrCode() {
	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/android/i.test(userAgent)) {
			window.location.href = 'https://play.google.com/store/apps/details?id=uz.cashbek.cabinet';
		} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			window.location.href = 'https://apps.apple.com/us/app/uget/id6471330757';
		} else {
			console.log("Web");
		}
	}, []);

	return (
		<>
			<div className="d-flex flex-column align-items-center justify-content-center h-100">
				<img src={logo} alt="" width={250} />

				<a href="https://play.google.com/store/apps/details?id=uz.cashbek.cabinet" style={{ marginTop: '80px' }}>
					<img src={play_market} alt="" width={200} />
				</a>

				<a href="https://apps.apple.com/us/app/uget/id6471330757" style={{ marginTop: '20px' }}>
					<img src={app_store} alt="" width={200} />
				</a>
			</div>
		</>
	)
}

export default QrCode