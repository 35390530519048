import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatMoney } from 'helpers/helpers'

function Rightbar({ showRightBar, setShowRightBar, selectedItem }) {
	const { t } = useTranslation()

	return (
		<>
			{showRightBar &&
				<>
					<div className="right-sidebar-background" onClick={() => setShowRightBar(!showRightBar)}></div>
					<div className="right-sidebar">

						<div className="d-flex justify-content-center position-relative mt-3 mb-1">
							<div className="rightbar-close-icon" onClick={() => setShowRightBar(!showRightBar)}>
								<i className="uil uil-times"></i>
							</div>
							<div className="d-flex flex-column">
								<div className="d-flex justify-content-center mb-3">
									<div className="logo-style">Uget</div>
								</div>
							</div>
						</div>

						<div className="cheque-block-2 cheque-border-bottom px-2">
							<table className="custom-cheque-table w-100 fz-14">
								<thead>
									<tr>
										<th className="w-50">№ {t('PRODUCT')}</th>
										<th>{t('QUANTITY')}</th>
										<th className="text-end">{t('PRICE')}</th>
									</tr>
								</thead>
								<tbody>
									{selectedItem?.itemsList?.map((item, index) => (
										<tr key={index}>
											<td>{item.name}</td>


											<td>
												{item.returnStatus === 0 &&
													<div>{item.quantity} x {formatMoney(item.price)}</div>
												}
												{item.returnStatus === 1 &&
													<div>
														<div><del>{item.quantity} x {formatMoney(item.price)}</del></div>
														{item.actualQuantity} x {formatMoney(item.price)}
													</div>
												}
												{item.returnStatus === 2 &&
													<del>{item.quantity} x {formatMoney(item.price)}</del>
												}
											</td>


											<td className="text-end">
												{item.returnStatus === 0 &&
													<div>{formatMoney(item.amount)}</div>
												}
												{item.returnStatus === 1 &&
													<div>
														<div><del>{formatMoney(item.amount)}</del></div>
														{formatMoney(item.actualAmount)}
													</div>
												}
												{item.returnStatus === 2 &&
													<div>{formatMoney(item.amount)}</div>
												}
											</td>
										</tr>
									))
									}
								</tbody>
							</table>
						</div>

					</div>
				</>
			}
		</>
	)
}

export default Rightbar
