import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'

import { GET } from 'helpers/api'
import { formatMoney, formatDateBackend } from 'helpers/helpers';

import 'react-loading-skeleton/dist/skeleton.css'

function Index() {
	const { t } = useTranslation()

	const date = new Date()

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

	const defaultGraphData = {
		series: [
			{
				name: t('VISITS'),
				data: []
			},
			{
				name: t('NEW_CLIENTS'),
				data: []
			},
		],
		options: {
			//colors: ['#C5D1FF', '#00E396', '#eeeeee'],
			chart: {
				height: 350,
				type: 'area',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd.MM.yyyy',
					rotate: -45,
					rotateAlways: true,
					hideOverlappingLabels: true,
					trim: true,
					style: {
						fontSize: '12px',
						colors: []
					}
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	}

	const [graphData, setGraphData] = useState(defaultGraphData)
	const [graphTotal, setGraphTotal] = useState({
		'visits': 0,
		'newCustomers': 0
	})
	const [show] = useState(!false)
	const [loader, setLoader] = useState(true)
	const [poses, setPoses] = useState([])
	const [filterData, setFilterData] = useState({
		'from': formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		'to': formatDateBackend(new Date(date.getFullYear(), date.getMonth(), date.getDate())),
		'posId': null
	})


	async function getData() {
		const response = await GET('/services/gocashweb/api/users-analytic', filterData, { loader: true })
		if (response) {
			var graphDataCopy = { ...graphData }
			graphDataCopy.series[0]['data'] = []
			graphDataCopy.series[1]['data'] = []
			graphDataCopy.options.xaxis.categories = []
			for (let i = 0; i < response['dashboard']?.length; i++) {
				graphDataCopy.series[0]['data'].push(response['dashboard'][i]['visitsCount'])
				graphDataCopy.series[1]['data'].push(response['dashboard'][i]['newCustomersCount'])

				graphDataCopy.options.xaxis.categories.push(response['dashboard'][i]['date'])
			}
			console.log(graphDataCopy);
			setGraphData(graphDataCopy)
			setGraphTotal({
				...graphTotal,
				'newCustomers': response.newCustomers,
				'visits': response.visits
			})
			setLoader(false)
			setDropdownIsOpen(false)
		}
	}

	async function getPoses() {
		const response = await GET('/services/gocashweb/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex gap-3 mb-2">
				<h4 className="vertical-center">
					{t('USERS_ANALYTIC')}
				</h4>
				<div>
					<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
						<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
							{t('FILTER')}
						</Dropdown.Toggle>

						<Dropdown.Menu className="dropdown-filter">
							<div className="row">
								<div className="form-group col-md-12">
									<label>{t('POS')}</label>
									<Select
										options={poses}
										value={poses.find(option => option.id === filterData.posId) || ''}
										onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group col-md-6">
									<label>{t('PERIOD')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.from ? new Date(filterData.from) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'from': formatDateBackend(date) })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<div className="form-group col-md-6">
									<label className="invisible">.</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.to ? new Date(filterData.to) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'to': formatDateBackend(date) })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<div className="d-flex justify-content-end">
										<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
										<button type="button" className="btn btn-primary" onClick={() => getData()}>{t('APPLY')}</button>
									</div>
								</div>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>

			{show &&
				<>
					{!loader &&
						<>
							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<h4>{t('STATISTICS')}</h4>
											</div>
											<div className="d-flex">
												<div className="d-flex flex-column justify-content-between me-5">
													<strong className="fz14">{t('VISITS')}</strong>
													<div className="position-relative">
														<span className="fz24 text-success">{formatMoney(graphTotal.visits, 0)}</span>
													</div>
												</div>
												<div className="d-flex flex-column justify-content-between me-5">
													<strong className="fz14">{t('NEW_CLIENTS')}</strong>
													<div className="position-relative">
														<span className="fz24 text-primary">{formatMoney(graphTotal.newCustomers, 0)}</span>
													</div>
												</div>
											</div>
											<hr />
											<ReactApexChart options={graphData.options} series={graphData.series} type="area" height={300} />
										</div>
									</div>
								</div>
							</div>
						</>
					}
					{loader &&
						<>
							<div className="row">
								<div className="col-md-12">
									<Skeleton height={400} />
								</div>
							</div>
						</>
					}
				</>
			}
		</>
	)
}

export default Index