import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify'
import DatePicker from "react-datepicker"
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { SketchPicker } from 'react-color'

import { DOWNLOAD_FILE, GET, PGET, POST, PUT } from 'helpers/api';
import { formatDate, formatDateBackend, formatMoney, regexPhoneNumber } from 'helpers/helpers';
import useDidMountEffect from 'pages/parts/useDidMountEffect';
import MessageModal from './MessageModal';
import ConfirmModal from 'pages/parts/ConfirmModal';

function Rfm() {
	const { t } = useTranslation()

	const date = new Date()

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [showColor, setShowColor] = useState(false)
	const [showNotificationModal, setShowNotificationModal] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)

	const [selectedSegment, setSelectedSegment] = useState({
		show: false,
		edit: false,
		item: '',
	})
	const [notification, setNotification] = useState({
		'titleUz': '',
		'titleRu': '',
		'messageUz': '',
		'messageRu': '',
		'imageUrl': '',
	})

	const [filterData, setFilterData] = useState({
		posId: '68',
		from: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		to: formatDateBackend(new Date()),
		random: 0,
	})

	const [poses, setPoses] = useState([])
	const [data, setData] = useState({})

	async function updateSegment() {
		var sendData = { ...selectedSegment.item }
		sendData.posId = filterData.posId
		const response = await PUT(`/services/gocashweb/api/rfm/segment/${filterData.posId}`, sendData)
		if (response) {
			setSelectedSegment({ ...selectedSegment, edit: false })
			getData()
		}
	}

	async function sendNotification(item) {
		var sendData = {
			...item,
			posId: filterData.posId,
			sendAll: item.sendAll ? true : false,
		}
		const response = await POST('/services/gocashweb/api/rfm/send-notification', sendData, { loader: true })
		for (let i = 0; i < response?.errors?.length; i++) {
			toast.error(response?.errors[i])
		}
		setNotification({
			'titleUz': '',
			'titleRu': '',
			'messageUz': '',
			'messageRu': '',
			'imageUrl': '',
		})
		setShowNotificationModal(false)
	}

	async function openNotificationModal(item) {
		var notificationCopy = { ...notification, ...item }
		if (item.messageId) {
			const response = await GET(`/services/gocashweb/api/messages/${item.messageId}`)
			if (response) {
				notificationCopy = { ...notificationCopy, ...response }
			}
		}
		setNotification(notificationCopy)
		setSelectedSegment({ ...selectedSegment, item: item })
		setShowNotificationModal(true)
	}

	async function donwloadRfm() {
		await DOWNLOAD_FILE(`/services/gocashweb/api/rfm/excel/${filterData.posId}`, t('RFM_ANALYTIC'), { loader: true })
	}

	async function donwloadSegment(item) {
		await DOWNLOAD_FILE(`/services/gocashweb/api/rfm/segment/excel/${filterData.posId}/${item.segment}`, t('SEGMENT'), { loader: true })
	}

	function changeSelectedSegment(name, value) {
		var selectedSegmentCopy = { ...selectedSegment }
		selectedSegmentCopy.item[name] = value
		setSelectedSegment(selectedSegmentCopy)
	}

	async function getClients(item) {
		const response = await PGET(`/services/gocashweb/api/rfm/segment/${filterData.posId}/${item.segment}`)
		if (response) {
			item.segmentClients = response.data
			setSelectedSegment({ ...selectedSegment, show: true, item: item })
		}
	}

	async function getData() {
		const response = await GET(`/services/gocashweb/api/rfm/${filterData.posId}`, filterData, { loader: true })
		setData(response)
		setDropdownIsOpen(false)
	}

	async function getPoses() {
		const response = await GET("/services/gocashweb/api/pos-helper")
		if (response) {
			setPoses(response)
			setFilterData({ ...filterData, posId: response[0].id, random: filterData.random + 1 })
		}
	}

	useEffect(() => {
		getPoses()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex justify-content-between">
				<div className="d-flex align-items-center">
					<h4 className="vertical-center me-3">
						{t('RFM_ANALYTIC')}
					</h4>

					<div>
						<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
							<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
								{t('FILTER')}
							</Dropdown.Toggle>

							<Dropdown.Menu className="dropdown-filter">
								<div className="row">
									<div className="form-group col-md-12">
										<label>{t('POS')}</label>
										<Select
											options={poses}
											value={poses.find(option => option.id === filterData.posId) || ''}
											onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group col-md-6">
										<label>{t('PERIOD')}</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.from ? new Date(filterData.from) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'from': formatDateBackend(date) })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
									<div className="form-group col-md-6">
										<label className="invisible">.</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.to ? new Date(filterData.to) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'to': formatDateBackend(date) })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<div className="d-flex justify-content-end">
											<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
											<button type="button" className="btn btn-primary" onClick={() => getData()}>{t('APPLY')}</button>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div>
						<button className="btn btn-outline-primary btn-wide" onClick={() => donwloadRfm()}>EXCEL</button>
					</div>
				</div>
				<div>
					<button className="btn-rounded btn btn-primary" onClick={() => setModalConfirm(true)}>
						{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
					</button>
				</div>
			</div>

			<div className="mt-3">
				<div>
					<div className="text-black fz-20 mb-1">
						<span>{t('ALL_CLIENTS_COUNT')}: </span>
						<span>{formatMoney(data.allClientCount, 0)}</span>
					</div>
					<div className="text-black fz-20">
						<span>{t('TOTAL_CHEQUE_SUM')}: </span>
						<span>{formatMoney(data.allClientsChequeSum, 0)} {t('SUM')}</span>
					</div>
				</div>

				<div className="analytics-rfm">
					<div className="row analytics-rfm-blocks">
						{data?.segmentInfo?.map((item, index) => (
							<div className="col-md-3" key={index} onClick={() => getClients(item)}>
								<div className="analytics-rfm-block shadow cursor">

									<div className="d-flex align-items-center justify-content-between">
										<OverlayTrigger placement="bottom"
											overlay={
												<Tooltip id="tooltip" className="custom-tooltip">
													{item.description}
												</Tooltip>
											}>
											<div className="header">
												{item.name}
												<i className="uil uil-question-circle ms-2"  ></i>
											</div>
										</OverlayTrigger>
										<div className="custom-dropdown m-0">
											<i className="uil uil-ellipsis-v fz-20"></i>
											<div className="custom-dropdown-menu">
												<div className="custom-dropdown-item" onClick={(e) => {
													e.stopPropagation()
													setSelectedSegment({ ...selectedSegment, edit: true, item: { ...item } })
												}}>
													<i className="uil uil-cog me-2"></i>
													{t('set_up_a_segment')}
												</div>
												<div className="custom-dropdown-item" onClick={(e) => {
													e.stopPropagation()
													openNotificationModal(item)
												}}>
													<i className="uil uil-telegram-alt me-2"></i>
													{t('SEND_NOTIFICATION')}
												</div>
												<div className="custom-dropdown-item" onClick={(e) => {
													e.stopPropagation()
													donwloadSegment(item)
												}}>
													<i className="uil uil-file-download-alt me-2"></i>
													EXCEL
												</div>
											</div>
										</div>
									</div>

									<div className="content" style={{ color: item.color }}>
										{formatMoney(item.segmentClientCountPercentage, 2)} %
									</div>
									<div className="footer">
										<div>
											{t('SEGMENT')}: {item.segment}
										</div>
										<div>
											<i className="uil uil-user me-1"></i>
											{formatMoney(item.segmentClientCount, 0)}
										</div>
									</div>
								</div>
							</div>
						))
						}
					</div>
				</div>
			</div>

			<Modal show={selectedSegment.show} animation={false}
				centered dialogClassName="update-modal-width" size="lg"
				onHide={() => setSelectedSegment({ ...selectedSegment, show: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex align-items-center">
						{selectedSegment.item.name}
						<div className="ms-2">
							<button className="btn btn-outline-primary btn-wide" onClick={() => donwloadSegment(selectedSegment.item)}>EXCEL</button>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-height">
					<div className="table-responsive table-sticky">
						<table className="table">
							<thead>
								<tr>
									<th>{t('NAME')}</th>
									<th className="text-end">{t('PHONE')}</th>
									<th className="text-end">{t('BALANCE')}</th>
									<th className="text-end">{t('LAST_ACTIVITY')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedSegment.item?.segmentClients?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNumber}. {item.client}</td>
										<td className="text-end">{regexPhoneNumber(item.phoneNumber)}</td>
										<td className="text-end">{formatMoney(item.clientBalance)}</td>
										<td className="text-end">{formatDate(item.lastPurchaseDate)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={selectedSegment.edit} animation={false}
				centered dialogClassName="update-modal-width" size="sm"
				onHide={() => setSelectedSegment({ ...selectedSegment, edit: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{t('UPDATE_SEGMENT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-height">
					<div className="form-group">
						<label>{t('NAME2')}</label>
						<input type="text" className="form-control" value={selectedSegment.item.name}
							onChange={(e) => changeSelectedSegment('name', e.target.value)} />
					</div>
					<div className="form-group">
						<label>{t('COLOR')}</label>
						<div className="position-relative">
							<input type="text" className="form-control" value={selectedSegment.item.color} name='color'
								onChange={(e) => changeSelectedSegment('color', e.target.value)} onClick={() => setShowColor(true)} />
							{showColor &&
								<div className="color-picker" >
									<SketchPicker onChange={(e) => changeSelectedSegment('color', e.hex)} color={selectedSegment.item.color} />
								</div>
							}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setSelectedSegment({ ...selectedSegment, edit: false })}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => updateSegment()}>{t('SAVE')}</button>
					</div>
				</Modal.Footer>
			</Modal>

			{showNotificationModal &&
				<MessageModal notification={notification} setNotification={setNotification}
					complete={(item) => sendNotification(item)} setShowNotificationModal={setShowNotificationModal}
					showNotificationModal={showNotificationModal} />
			}

			<ConfirmModal modalConfirm={modalConfirm} setModalConfirm={setModalConfirm} confrim={() => sendNotification({ sendAll: true })} />
		</>
	)
}

export default Rfm