import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { DELETE, GET, POST, PUT } from 'helpers/api'
import { Modal } from "react-bootstrap"
import { SketchPicker } from "react-color"
import MessageModal from "./MessageModal";

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();

	const wrapperRef = useRef(null);

	const [showColor, setShowColor] = useState(false)
	const [showNotificationModal, setShowNotificationModal] = useState(false)

	const [data, setData] = useState({
		'posList': [],
		'name': "",
		"recencySettings": [
			{
				"recencyValue": "",
				"recencyStatus": "2"
			},
			{
				"recencyValue": "",
				"recencyStatus": "3"
			}
		],
		"frequencySettings": [
			{
				"frequencyValue": "",
				"frequencyStatus": "2"
			},
			{
				"frequencyValue": "",
				"frequencyStatus": "3"
			}
		],
		"monetarySettings": [
			{
				"monetaryValue": "",
				"monetaryStatus": "2"
			},
			{
				"monetaryValue": "",
				"monetaryStatus": "3"
			}
		],
	})
	const [selectedSegment, setSelectedSegment] = useState({
		edit: false,
		item: '',
	})
	const [notification, setNotification] = useState({
		'titleUz': '',
		'titleRu': '',
		'messageUz': '',
		'messageRu': '',
		'imageUrl': '',
	})

	const [posGroups, setPosGroups] = useState([])
	const [poses, setPoses] = useState([])

	async function saveNotification(notificationCopy) {
		setShowNotificationModal(false)
		var dataCopy = { ...data }
		var index = dataCopy.segmentSettings.findIndex(item => item.segment === notificationCopy.segment)
		dataCopy.segmentSettings[index].messageId = notificationCopy.messageId
		setData(dataCopy)
	}

	async function openNotificationModal(item) {
		var notificationCopy = { ...notification, ...item }
		if (item.messageId) {
			const response = await GET(`/services/gocashweb/api/messages/${item.messageId}`)
			if (response) {
				notificationCopy = { ...notificationCopy, ...response }
			}
		}
		setNotification(notificationCopy)
		setShowNotificationModal(true)
	}

	async function createUpdate() {
		var dataCopy = { ...data }
		var response;
		dataCopy['posList'] = []
		for (let j = 0; j < poses.length; j++) {
			dataCopy['posList'].push(poses[j])
		}
		if (!id) {
			response = await POST('/services/gocashweb/api/rfm-settings', dataCopy)
		} else {

			response = await PUT('/services/gocashweb/api/rfm-settings', dataCopy)
		}

		if (response) {
			navigate('/rfm/settings')
		}
	}

	function handleChange(name, index, secondName, value) {
		var copyData = { ...data }
		copyData[name][index][secondName] = value;
		setData(copyData)
	}

	async function updateSegment() {
		setSelectedSegment({ ...selectedSegment, edit: false })
	}

	function changeSelectedSegment(name, value) {
		var selectedSegmentCopy = { ...selectedSegment }
		selectedSegmentCopy.item[name] = value
		setSelectedSegment(selectedSegmentCopy)
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/rfm-settings/' + id, {}, { loader: true })
		if (response) {
			setData(response)
			setPoses(response.posList)
		}
	}

	async function getInitData() {
		const response = await GET('/services/gocashweb/api/rfm-settings-helper', {}, { loader: true })
		if (response) {
			setData(response)
			setPoses(response.posList)
		}
	}

	async function deletePos(index) {
		var copyPoses = [...poses]
		var item = copyPoses[index]
		if (item.settingId) {
			await DELETE(`/services/gocashweb/api/rfm-settings/pos`, {
				settingId: id,
				posId: item.id,
			})
		}
		copyPoses?.splice(index, 1)
		getPosGroups()
		setPoses(copyPoses)
	}

	async function getPosGroups() {
		const response = await GET('/services/gocashweb/api/rfm-settings-pos-helper')
		if (response) {
			setPosGroups(response)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		} else {
			getInitData()
		}
		getPosGroups()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShowColor(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]); // eslint-disable-line react-hooks/exhaustive-deps

	const MultiValueRemove = () => {
		return <div className="ms-1"></div>;
	};

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CREATE')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<h4>
						<strong>{t('REGULATIONS')}</strong>
					</h4>
					<hr />



					<div className="row">
						<div className="col-md-4">
							<div className="form-group">
								<label>{t('NAME3')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control"
									value={data.name}
									onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>

							<div className="d-flex align-items-center">
								<div className="form-group w-100 me-3">
									<label>{t('RECENCY')}({t('DAYS')})</label>
									<input type="text" className="form-control" value={data.recencySettings[0].recencyValue}
										onChange={(e) => handleChange('recencySettings', 0, 'recencyValue', e.target.value)} />
								</div>

								<div className="form-group w-100">
									<label className="label-invisible">.</label>
									<input type="text" className="form-control" value={data.recencySettings[1].recencyValue}
										onChange={(e) => handleChange('recencySettings', 1, 'recencyValue', e.target.value)} />
								</div>
							</div>

							<div className="d-flex align-items-center">
								<div className="form-group w-100 me-3">
									<label>{t('FREQUENCY')}</label>
									<input type="text" className="form-control" value={data.frequencySettings[0].frequencyValue}
										onChange={(e) => handleChange('frequencySettings', 0, 'frequencyValue', e.target.value)} />
								</div>

								<div className="form-group w-100">
									<label className="label-invisible">.</label>
									<input type="text" className="form-control" value={data.frequencySettings[1].frequencyValue}
										onChange={(e) => handleChange('frequencySettings', 1, 'frequencyValue', e.target.value)} />
								</div>
							</div>

							<div className="d-flex align-items-center">
								<div className="form-group w-100 me-3">
									<label>{t('MONETARY')}</label>
									<input type="text" className="form-control" value={data.monetarySettings[0].monetaryValue}
										onChange={(e) => handleChange('monetarySettings', 0, 'monetaryValue', e.target.value)} />
								</div>

								<div className="form-group w-100">
									<label className="label-invisible">.</label>
									<input type="text" className="form-control" value={data.monetarySettings[1].monetaryValue}
										onChange={(e) => handleChange('monetarySettings', 1, 'monetaryValue', e.target.value)} />
								</div>
							</div>

							<h4 className="mt-3">
								<strong>Места внедрения</strong>
							</h4>
							<hr />

							<Select
								className="w-100 mb-3"
								options={posGroups}
								value={poses}
								isMulti
								isClearable={false}
								onChange={(options) => setPoses(options)}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								components={{ MultiValueRemove }}
							/>
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('POS')}</th>
											<th className="text-end">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{poses?.map((item, index) => (
											<tr key={index}>
												<td>{item.name}</td>
												<td className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button me-2" onClick={() => deletePos(index)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
						<div className="col-md-8">
							<div className="table-responsive" style={{ maxHeight: '530px' }}>
								<table className="table">
									<thead>
										<tr>
											<th>{t('SEGMENT')}</th>
											<th>{t('SEGMENT')} {t('NAME2')}</th>
											<th>{t('DESCRIPTION')}</th>
											<th>{t('COLOR')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>

									<tbody>
										{data?.segmentSettings?.map((item, index) => (
											<tr key={index}>
												<td>{item.segment}</td>
												<td>{item.name}</td>
												<td>{item.description}</td>
												<td>
													<div className="d-flex align-items-center">
														{/* {item.color} */}
														<div className="color-block ms-2" style={{ backgroundColor: item.color }} />
													</div>
												</td>
												<td>
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-primary-button me-2" onClick={() => setSelectedSegment({ ...selectedSegment, item: item, edit: true })}>
															<i className="uil-edit"></i>
														</div>
														<div className="table-action-button table-action-primary-button" onClick={() => openNotificationModal(item)}
															title={t('NOTIFICATION')}>
															<i className="uil-telegram-alt"></i>
														</div>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="btn-wrapper">
						<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/services-by-amount">{t('CANCEL')}</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide" onClick={() => createUpdate()}>
							{t('SAVE')}
						</button>
					</div>

				</div>
			</div>

			<Modal show={selectedSegment.edit} animation={false}
				centered dialogClassName="update-modal-width" size="md"
				onHide={() => setSelectedSegment({ ...selectedSegment, edit: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{t('UPDATE_SEGMENT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-height">
					<div className="form-group">
						<label>{t('NAME2')}</label>
						<input type="text" className="form-control" value={selectedSegment.item.name}
							onChange={(e) => changeSelectedSegment('name', e.target.value)} />
					</div>
					<div className="form-group">
						<label>{t('COLOR')}</label>
						<div className="position-relative" ref={wrapperRef}>
							<input type="text" className="form-control" value={selectedSegment.item.color} name='color'
								onChange={(e) => changeSelectedSegment('color', e.target.value)} onClick={() => setShowColor(true)} />
							{showColor &&
								<div className="color-picker" >
									<SketchPicker onChange={(e) => changeSelectedSegment('color', e.hex)} color={selectedSegment.item.color} />
								</div>
							}
						</div>
					</div>
					<div className="form-group">
						<label>{t('DESCRIPTION')}</label>
						<textarea type="text" className="form-control" value={selectedSegment.item.description}
							onChange={(e) => changeSelectedSegment('description', e.target.value)} rows={6} />
					</div>

				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setSelectedSegment({ ...selectedSegment, edit: false })}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => updateSegment()}>{t('SAVE')}</button>
					</div>
				</Modal.Footer>
			</Modal>

			{showNotificationModal &&
				<MessageModal notification={notification} setNotification={setNotification}
					showNotificationModal={showNotificationModal}
					complete={saveNotification} setShowNotificationModal={setShowNotificationModal} />
			}
		</>
	)
}
export default CreateUpdate