import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PGET } from 'helpers/api'
import { formatMoney, regexPhoneNumber } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET('/services/gocashweb/api/pos-list', {}, { loader: true })
		if (response) {
			setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('BUSINESSES')}
				</h4>
				{/* <div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to={'/pos-group/pos/create/' + id}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div> */}
			</div>

			<div className="row">

				{data.map((item, index) => (
					<div className="col-md-3 text-center position-relative mb-3">
						<div className="card">
							<div className="card-body py-4">
								{item.logoUrl ?
									<img src={'https://cabinet.cashbek.uz' + item.logoUrl} alt="react" width="200" height="150" />
									:
									<div className="table-no-image m-auto" style={{ width: '200px', height: '150px' }}>
										<i className="uil uil-camera position-relative fz-50" style={{ 'top': '20%' }}></i>
									</div>
								}
								<div className="d-flex justify-content-between fw-600 mb-1 mt-3">
									<div className="color-primary">
										{t('TITLE')}
									</div>
									<div className="">
										{item.name}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600 mb-1">
									<div className="color-primary">
										{t('PHONE')}
									</div>
									<div className="">
										{regexPhoneNumber(item.phone)}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600 mb-1">
									<div className="color-primary">
										{t('CATEGORY')}
									</div>
									<div className="">
										{item.categoryName}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600 mb-1">
									<div className="color-primary">
										{t('ACTIVATED')}
									</div>
									<div className={item.activated ? 'text-success' : 'text-danger'}>
										{item.activated ? t('YES') : t('NO')}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600 mb-1">
									<div className="color-primary">
										{t('BALANCE')}
									</div>
									<div className={item.balance > 0 ? 'text-success' : 'text-danger'}>
										{formatMoney(item.balance, 0)} {t('SUM')}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600 mb-1">
									<div className="color-primary">
										{t('TARIFF')}
									</div>
									<div className="">
										{formatMoney(item.tariff, 0)}  {t('SUM')}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600">
									<div className="color-primary">
										{t('SERVICE')} ID
									</div>
									<div className="">
										{item.serviceId}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600">
									<div className="color-primary">
										{t('SERVICE_NAME')}
									</div>
									<div className="">
										{item.serviceName}
									</div>
								</div>
								<div className="d-flex justify-content-between fw-600">
									<div className="color-primary">
										{t('SERVICE_TYPE_ID')}
									</div>
									<div className="">
										{item.serviceTypeName}
									</div>
								</div>
							</div>
						</div>
						<Link to={`/pos/update/${item.id}`}>
							<div className="table-action-button table-action-primary-button position-absolute" style={{ top: 15, right: 15 }}>
								<i className="uil uil-pen"></i>
							</div>
						</Link>
					</div>
				))
				}
			</div>

			{/* <div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>



					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('IMAGE2')}</th>
									<th>{t('SALE_POINT_NAME')}</th>
									<th className="text-center">{t('KIND_OF_ACTIVITY')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('KEY')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td>
											{item.imageUrl ?
												<img src={'https://cabinet.cashbek.uz' + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td className="text-center">{item.posTypeName}</td>
										<td className="text-center">{item.activated ? t('YES') : t('NO')}</td>
										<td className="text-center">{item.posApiKey}</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link className="table-action-button table-action-success-button"
													to={'/pos-group/pos/' + id + '/' + item.id}>
													<i className="uil-edit-alt"></i>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div> */}
		</>
	)
}

export default Index
