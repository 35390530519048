import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'

import { Modal } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';

import { DELETE, GET, POST, PUT } from 'helpers/api'
import { findFromArrayById } from "helpers/helpers";
import ConfirmModal from "pages/parts/ConfirmModal";

function Index() {
	const { t } = useTranslation()

	const editorRefRu = useRef(null);
	const editorRefUz = useRef(null);

	const [data, setData] = useState([])
	const [messageTypes, setMessageTypes] = useState([])
	const [selectedItem, setSelectedItem] = useState({
		templateName: '',
		titleUz: '',
		titleRu: '',
		messageUz: '',
		messageRu: '',
	})

	const [showModal, setShowModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState({ show: false, id: '' })

	async function createUpdate() {
		var selectedItemCopy = { ...selectedItem }
		var response;
		if (editorRefUz.current) {
			selectedItemCopy.messageUz = editorRefUz.current.getContent();
		}
		if (editorRefRu.current) {
			selectedItemCopy.messageRu = editorRefRu.current.getContent();
		}
		if (selectedItemCopy.id) {
			response = await PUT('/services/gocashweb/api/messages', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/services/gocashweb/api/messages', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function deleteTemplate() {
		const response = await DELETE(`/services/gocashweb/api/messages`, { id: showConfirmModal.id }, { loader: true })
		if (response) {
			setShowConfirmModal({ show: false, id: '' })
			getData()
		}
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/messages-pageList', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	async function getMessageTypes() {
		const response = await GET('/services/gocashweb/api/message-type-helper', {}, { loader: true })
		if (response) {
			setMessageTypes(response.slice(0, 2))
		}
	}

	useEffect(() => {
		getData()
		getMessageTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('MESSAGE_TEMPLATES')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th style={{ width: '10%' }}>{t('NAME')}</th>
									<th style={{ width: '10%' }}>{t('TYPE')}</th>
									<th style={{ width: '10%' }}>{t('TITLE')} (RU)</th>
									<th style={{ width: '10%' }}>{t('TITLE')} (UZ)</th>
									<th style={{ width: '20%' }}>{t('MESSAGE')} (RU)</th>
									<th style={{ width: '20%' }}>{t('MESSAGE')} (UZ)</th>
									<th style={{ width: '5%' }} className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.templateName}</td>
										<td>{findFromArrayById(messageTypes, item.messageTemplateType)}</td>
										<td>{item.titleRu}</td>
										<td>{item.titleUz}</td>
										<td dangerouslySetInnerHTML={{ __html: item.messageRu }}></td>
										<td dangerouslySetInnerHTML={{ __html: item.messageUz }}></td>
										<td className="d-flex justify-content-center gap-2">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
											<div className="table-action-button table-action-danger-button" onClick={() => setShowConfirmModal({ ...showConfirmModal, show: true, id: item.id })}>
												<i className="uil-trash-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => createUpdate()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.templateName}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'templateName': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('TYPE')}<span className="required-mark">*</span></label>
							<Select
								options={messageTypes}
								value={messageTypes.find(option => option.id === selectedItem.messageTemplateType) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'messageTemplateType': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('TITLE')} (RU)<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.titleRu}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'titleRu': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('TITLE')} (UZ)<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.titleUz}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'titleUz': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (ru)<span className="required-mark">*</span></label>
							<Editor
								apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
								onInit={(evt, editor) => editorRefRu.current = editor}
								initialValue={selectedItem.messageRu}
								init={{
									height: 300,
									menubar: true,
									language: 'ru',
									plugins: [
										'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
										'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
										'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
									],
									toolbar: 'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
							/>
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (uz)<span className="required-mark">*</span></label>
							<Editor
								apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
								onInit={(evt, editor) => editorRefUz.current = editor}
								initialValue={selectedItem.messageUz}
								init={{
									height: 300,
									menubar: true,
									language: 'ru',
									plugins: [
										'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
										'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
										'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
										'spellchecker',
									],
									toolbar: 'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help | spellchecker',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<ConfirmModal modalConfirm={showConfirmModal.show} setModalConfirm={setShowConfirmModal} confrim={deleteTemplate} />
		</>
	)
}

export default Index
