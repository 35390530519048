import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'

import { setNavTab } from 'store/layout/actions';
//Import Scrollbar
import SimpleBar from "simplebar-react"
import { Nav } from 'react-bootstrap'
import { exportMenu } from './menu'
// import { exportMenu2 } from './menu2'

function SidebarContent() {
	const { t } = useTranslation();
	const ref = useRef();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const navTabKey = useSelector(state => state.Layout.navTabKey)

	const [menu, setMenu] = useState(exportMenu)
	//const [navTabKey, setNavTabKey] = useState('link_1')

	function setActiveMenu() {
		for (let i = 0; i < menu.length; i++) {

			if (location.pathname.includes(menu[i].url)) {
				showItem(i)
			}
			if (location.pathname.includes(menu[i].url)) {
				if (menu[i].children?.length > 0) {
					showItem(i)
					break;
				}
			}

		}
	}

	function showItem(index) {
		let copy = [...menu];
		let copyItem = { ...menu[index] };
		copyItem.active = !copyItem.active;
		copy[index] = copyItem;
		setMenu(copy);
	}

	function setNav(link) {
		if (link === 'link_3') {
			dispatch(setNavTab('link_1'))
			navigate('/settings')
		} else {
			dispatch(setNavTab(link))
		}
	}

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
				<Nav variant="tabs" activeKey={navTabKey}>
					<Nav.Item onClick={() => setNav('link_1')}>
						<Nav.Link eventKey="link_1" tabIndex="-1">
							<span className="fw-bold">{t('MENU')}</span>
						</Nav.Link>
					</Nav.Item>
				</Nav>

				{navTabKey === "link_1" &&
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{menu.map((item, index) => (
								<li className={item.active ? "mm-active" : ""} key={index}>
									<Link to={item.url} className={"waves-effect " + (item.active ? "active" : "")} tabIndex="-1">
										<i className={item.icon}></i>
										<span>{t(item.i18n)}</span>
									</Link>
								</li>
							))
							}
						</ul>
					</div>
				}

			</SimpleBar>
		</>
	)
}

export default SidebarContent