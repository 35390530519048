import React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { userRoutes, authRoutes } from './allRoutes'

import Layout from "../components/VerticalLayout/index";
import NoAuthLayout from "../components/NonAuthLayout";

function createPublicRoutes() {
	return authRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

function createPrivateRoutes() {
	return userRoutes.map((item, key) => {
		return (
			<Route key={key} element={<ProtectedRoute />}>
				<Route path={item.path} element={item.component} />
			</Route>
		);
	});
}

const ProtectedRoute = () => {
	const login = localStorage.getItem("username");
	var isAuthenticated = false;
	if (login) {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('username')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/login" />;
	}

	return <Outlet />;
};

const Index = () => {
	return (
		<Routes>
			<Route element={<NoAuthLayout />}>
				{createPublicRoutes()}
			</Route>

			<Route element={<Layout />}>
				{createPrivateRoutes()}
			</Route>

			<Route element={<Layout />}>
				<Route path="*" element={<Navigate to="/login" replace />} />
			</Route>

		</Routes>
	)
}

export default Index;